
ul.thesis-list {

  display: flex;
  flex-direction: column;
  gap: 2rem;

  & .thesis-item {
    position: relative;

    & input.sgl-check {
      position: absolute;
      top: 9px;
      left: 9px;
    }
  }

  & > div.thesis-item > li {
    border: 1px solid var(--Bright);
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.3s;
  }

  & > div.thesis-item > li:has(input.sgl-check:checked) {
    border-color: var(--Jet);
  }

  & > div.thesis-item > li .box {
    padding: 2rem;

    & + .box {
      border-top: 1px solid var(--Bright);
    }
  }

  & hgroup {
    position: relative;
    padding-left: 4rem !important;

    & h1 {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.3;
    }

    & h2 {
      margin-top: 6px;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.3;
      color: var(--Cloud);
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  & div.info dl {
    display: flex;
    align-items: flex-start;

    & + dl {
      margin-top: 2rem;
    }

    & dt {
      flex: 0 0 6rem;
      height: 2.6rem;
      padding: 0 8px;
      background: var(--Dim);
      border-radius: 6px;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2.6rem;
      color: #fff;
      box-sizing: border-box;
    }

    & dd {
      flex: 0 1 auto;
      margin-left: 16px;

      & p {
        max-height: 4.2rem;
        padding-top: 4px;
        line-height: 1.3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        & + p {
          margin-top: 6px;
        }
      }

      & span.sub {
        font-size: 1.4rem;
        color: var(--Gray);
      }
    }
  }

  & ul.author {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;

    & li {
      flex: 0 0 auto;
      height: 2.6rem;
      margin: 2px;
      padding: 0 8px;
      border: 1px solid var(--Bright);
      border-radius: 6px;
      box-sizing: border-box;

      & span.main {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 24px;
      }

      & span.sub {
        font-size: 1.3rem !important;
        line-height: 2.4rem;
      }
    }
  }

  & ul.keyword {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;

    & li {
      flex: 0 0 auto;
      height: 2.6rem;
      margin: 2px;
      padding: 0 8px;
      border: 1px solid var(--Bright);
      border-radius: 6px;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 24px;
      box-sizing: border-box;
    }

    & li:hover {
      cursor: pointer;
    }
  }

  & div.func {
    display: flex;
    gap: 0.8rem;
    background: var(--Ghost);

    & a, & span {
      position: relative;
      flex: 0 0 auto;
      height: 3rem;
      padding: 0 1rem 0 3rem;
      background-color: #fff;
      border: 1px solid var(--Bright);
      border-radius: 6px;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 3rem;
      transition: all 0.3s;
    }

    & a:after, & span:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 3rem;
      height: 3rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px;
      content: "";
    }

    & a.origin:after, & span.origin:after {
      background-image: url("../assets/image/icon-link-origin.svg");
    }

    & a.download:after, & span.download:after {
      background-image: url("../assets/image/icon-link-download.svg");
    }

    & a.website:after, & span.website:after {
      background-image: url("../assets/image/icon-link-website.svg");
    }

    & a.detail:after, & span.detail:after {
      background-image: url("../assets/image/icon-link-detail.svg");
    }

    & a:hover, & span:hover {
      padding: 0 16px 0 36px;
      border-color: var(--Strong);
      color: var(--Strong);
    }
  }
}
