ul.journal-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > li {
    border: 1px solid var(--Bright);
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.3s;
  }

  & span {
    display: block;
    padding: 2rem;
  }

  & h1 {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.3;
    transition: all 0.3s;
  }

  & h2 {
    margin-top: 6px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
    color: var(--Cloud);
    transition: all 0.3s;
  }

  & p.desc {
    display: flex;
    gap: 6px;
    margin-top: 1.2rem;

    & span {
      flex: 0 0 auto;
      padding: 6px;
      background: var(--Gray);
      border-radius: 4px;
      font-size: 1.4rem;
      font-weight: 500;
      color: #fff;
      transition: all 0.3s;
    }
  }
}

ul.journal-list > li:hover {
  border-color: var(--Strong);
}

ul.journal-list > li:hover h1 {
  color: var(--Strong);
}

ul.journal-list > li:hover h2 {
  color: var(--Dim);
}

ul.journal-list > li:hover p.desc span {
  background: var(--Dim);
}
