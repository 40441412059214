#dim {
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.modal {
  border: 1px solid var(--Dark);
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  pointer-events: auto;

  & .header {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    & .close-button {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      bottom: 0;
      right: 1rem;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &::before {
        content: ' ';
        width: 28px;
        height: 28px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 12px;
        background-image: url("../assets/image/button-close.svg");
      }

      &:hover {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: var(--Jet);
      }

      &:hover::before {
        content: ' ';
        width: 28px;
        height: 28px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 12px;
        background-image: url("../assets/image/button-close-hover.svg");
      }
    }
  }
}