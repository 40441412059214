.top-section {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  & .project-info {
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  & .description {
    color: var(--KD-GRAY, #999);
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
  }

  & .back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-85%);
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/arrow-back.svg");
  }

  & .buttons {
    display: flex;
    gap: 8px;

    & > div {
      display: flex;
      height: 24px;
      padding: 0 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 6px;
      border: 1px solid var(--KD-SILVER, #BBB);
      background: var(--KD-SNOW, #FFF);

      & .edit-icon {
        width: 14px;
        height: 14px;
        background-image: url("../assets/image/edit-button.svg");
      }

      & .delete-icon {
        width: 14px;
        height: 14px;
        background-image: url("../assets/image/trash.svg");
        background-size: 14px 14px;
      }

      & button {
        color: var(--KD-SILVER, #BBB);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
      }
    }
  }
}

.topic-tree-wrap {
  border-radius: 12px;
  background: var(--KD-JET, #333);
  color: var(--KD-SNOW, #FFF);
  padding: 12px;
}

.side-topic-tree {
  position: relative;
  display: flex;
  width: 240px;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: var(--KD-JET, #333);
  color: var(--KD-SNOW, #FFF);
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  & .icon {
    width: 22px;
    height: 22px;

    &.tree {
      background-image: url("../assets/image/tree-active.svg");
    }

    &.memo {
      background-image: url("../assets/image/memo-active.svg");
    }

    &.article {
      background-image: url("../assets/image/article-active.svg");
    }

    &.material {
      background-image: url("../assets/image/material-active.svg");
    }

    &.create-topic-tree {
      position: absolute;
      cursor: pointer;
      width: 22px;
      height: 22px;
      background-image: url("../assets/image/create-topic-tree.svg");
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.menus {
  margin-top: 12px;
  display: flex;
  padding: 12px;
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  background: var(--KD-JET, #333);
  color: var(--KD-SNOW, #FFF);
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  & .menu {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 28px;
    align-items: center;
    gap: 6px;

    & span {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 500;
      line-height: 100%;
    }

    &.on {
      color: var(--KD-FL, #00FFF0);

      & span {
        color: var(--KD-POINT, #FFB800);
      }

      & .icon.memo {
        background-image: url("../assets/image/memo-active-on.svg");
      }

      & .icon.article {
        background-image: url("../assets/image/article-active-on.svg");
      }
    }
  }

  & .divider {
    width: 100%;
    height: 1px;
    background: #777;
  }

  & .icon {
    width: 22px;
    height: 22px;

    &.tree {
      background-image: url("../assets/image/tree-active.svg");
    }

    &.memo {
      background-image: url("../assets/image/memo-active.svg");
    }

    &.article {
      background-image: url("../assets/image/article-active.svg");
    }

    &.material {
      background-image: url("../assets/image/material-active.svg");
    }

    &.trash {
      background-image: url("../assets/image/trash-active.svg");
    }

    &.manual {
      background-image: url("../assets/image/help-active.svg");
    }

    &.create-topic-tree {
      position: absolute;
      cursor: pointer;
      width: 22px;
      height: 22px;
      background-image: url("../assets/image/create-topic-tree.svg");
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.empty-project {
  display: flex;
  width: 920px;
  height: 480px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  & .info {
    color: var(--KD-GRAY, #999);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }

  & button.create-topic {
    display: flex;
    height: 40px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--KD-ID, #502ACC);
    color: var(--KD-SNOW, #FFF);
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
  }
}

.tacto-project {
  display: flex;
  width: 920px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .topic-info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    min-height: 34px;

    & .title {
      color: var(--KD-JET, #333);
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
    }

    & .description {
      color: var(--KD-GRAY, #999);
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;
    }
  }

  & .topic-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    & .right-container {
      display: flex;
      gap: 8px;
    }

    & .buttons {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;

      & .options-container {
        position: relative;
      }

      & .button-options {
        padding: 16px;
        display: flex;
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;

        position: absolute;
        z-index: 2;
        top: 45px;
        left: 0;

        border-radius: 8px;
        border: 1px solid var(--KD-ID, #502ACC);
        background: var(--KD-ID, #502ACC);
        color: var(--KD-SNOW, #FFF);
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;

        & div {
          cursor: pointer;
          text-wrap: nowrap;
        }
      }

      & .checkbox {
        display: flex;
        height: 40px;
        padding: 0 16px 0 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--KD-GRAY, #999);
        color: var(--KD-GRAY, #999);
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
      }

      & .button {
        border-radius: 8px;
        border: 1px solid var(--KD-JET, #333);
        display: flex;
        height: 40px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;

        & .icon {
          width: 24px;
          height: 24px;

          &.topic-add-button {
            background-image: url("../assets/image/topic-add.svg");
          }

          &.topic-copy-button {
            background-image: url("../assets/image/topic-copy.svg");
          }

          &.topic-move-button {
            background-image: url("../assets/image/topic-move.svg");
          }

          &.topic-export-button {
            background-image: url("../assets/image/topic-export.svg");
          }

          &.topic-delete-button {
            background-image: url("../assets/image/topic-delete.svg");
          }
        }
      }
    }

    & .search {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & input {
        width: 360px;
        height: 40px;
        padding: 0 12px;
      }

      & button {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        background-image: url("../assets/image/search.svg");
      }
    }
  }

  & .project-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-top: 2px solid var(--KD-JET, #333);
  }

  & .project-item-wrap {
    position: relative;
    display: flex;
    padding: 12px 12px 12px 40px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--KD-MIST, #DDD);

    & .sgl-check {
      position: absolute;
      left: 12px;
      top: 15px;
    }
  }

  & .material {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    & .topic-name {
      color: var(--KD-SILVER, #BBB);
      font-size: 13px;
      font-weight: 700;
      line-height: 100%;
    }

    & .title {
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 600;
      line-height: 130%;
    }

    & .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    & .left-container {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      max-width: 600px;

      &.memo {
        max-width: none;
      }

      & div:last-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & .divider {
        background: var(--KD-SILVER, #BBB);
        width: 1px;
        height: 10px;
      }
    }

    & .attachment-memo-container {
      display: flex;
      align-items: center;
      gap: 8px;

      & .memo-title {
        color: var(--KD-DIM, #777);
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
      }

      & .updated-at {
        color: var(--KD-GRAY, #999);
        font-size: 13px;
        font-weight: 500;
        line-height: 100%;
      }

      & button {
        display: flex;
        height: 24px;
        padding: 0 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 6px;
        border: 1px solid var(--KD-SILVER, #BBB);
        background: var(--KD-SNOW, #FFF);
        color: var(--KD-SILVER, #BBB);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;

        & .icon {
          width: 14px;
          height: 14px;

          &.memo {
            background-image: url("../assets/image/memo-button.svg");
          }

          &.attachment {
            background-image: url("../assets/image/attachment-button.svg");
          }
        }

        &.existed {
          background: var(--KD-JET, #333);
          color: var(--KD-SNOW, #FFF);

          & .icon {

            &.memo {
              background-image: url("../assets/image/memo-button-existed.svg");
            }

            &.attachment {
              background-image: url("../assets/image/attachment-button-existed.svg");
            }
          }
        }
      }
    }

    & .updated-at {
      color: var(--KD-GRAY, #999);
      font-size: 12px;
      font-weight: 600;
      line-height: 100%;
    }
  }

  & .tactoArticle {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    & .title {
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 600;
      line-height: 130%;
    }

    & .content {
      height: 54px;
      align-self: stretch;
      overflow: hidden;
      color: var(--KD-GRAY, #999);
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 500;
      line-height: 130%;
    }

    & .progress-container {
      display: flex;
      align-items: center;
      gap: 8px;

      & .progress-badge {
        display: flex;
        height: 24px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;

        &.completed {
          background: var(--KD-LOOK, #F90874);
        }

        & .dot {
          width: 4px;
          height: 4px;
          background: #DDD;
          border-radius: 50%;
        }
      }

      & .updated-at {
        color: var(--KD-GRAY, #999);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
      }
    }
  }
}