div.guide-wrap {
  margin-top: 40px;
}

div.guide-wrap p {
  line-height: 1.5;
}

div.guide-wrap p.attention {
  padding: 40px 40px 260px;
  background: var(--Strong) url("../assets/image/img-intro.png") center bottom no-repeat;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
}

div.guide-wrap p.note {
  margin-top: 10px;
  padding: 9px;
  background: var(--Ghost);
  border: 1px solid var(--Bright);
  font-size: 14px;
  font-weight: 500;
  color: var(--Alert);
}

div.guide-wrap p.figure {
  display: inline-block;
  max-width: 920px;
  padding: 9px;
  border: 1px solid var(--Bright);
  border-radius: 24px;
  box-sizing: border-box;
  line-height: 0;
}

div.guide-wrap p.figure img {
  max-width: 100%;
}

div.guide-wrap p + p {
  margin-top: 20px;
}

div.guide-wrap ul.numeric {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

div.guide-wrap ul.numeric li {
  flex: 0 0 160px;
}

div.guide-wrap ul.numeric li + li {
  margin-left: 60px;
}

div.guide-wrap ul.numeric li dl {
}

div.guide-wrap ul.numeric li dt {
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

div.guide-wrap ul.numeric li:first-child dt {
  background-image: url("../assets/image/img-thesis.png");
}

div.guide-wrap ul.numeric li:nth-child(2) dt {
  background-image: url("../assets/image/img-journal.png");
}

div.guide-wrap ul.numeric li:last-child dt {
  background-image: url("../assets/image/img-author.png");
}

div.guide-wrap ul.numeric li dd {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}