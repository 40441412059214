.above {
  margin-top: 20px;
}

.language-select {
  height: 40px;
}

div.table-wrapper {
  border: 1px solid var(--Bright);
  border-radius: 10px;
  overflow: hidden;

  & table {
    width: 100%;

    .f14 {
      font-size: 14px;
    }

    & thead {
      & th {
        padding: 10px 6px;
        background: var(--Ghost);
        border-bottom: 1px solid var(--Bright);
        font-weight: 600;
      }

      & th + th {
        border-left: 1px solid var(--Bright);
      }
    }

    & tbody {
      & tr {
        & td {
          padding: 10px 6px;
          transition: all 0.3s;
          text-align: center;
        }

        & td:hover {
          background: var(--Ghost);
        }
      }

      & tr:not(:last-child) {
        & td {
          border-bottom: 1px solid var(--Bright);
        }
      }

      & td + td {
        border-left: 1px solid var(--Bright);
      }
    }

    & button.new-open {
      padding: 0;
      background: none;
      border: 0 none;
      font-weight: 400;
      color: var(--Link);
    }

    & button.new-open:hover {
      color: var(--Strong);
    }

    & a.link {
      color: var(--Link);
      text-decoration: underline;
    }
  }
}