section.login {
  width: 40rem;
  margin: 0 auto;

  & h1 {
    font-size: 3rem;
    font-weight: 700;
  }

  & ul.input {
    margin-top: 4rem;

    & li {
      position: relative;
    }

    & li + li {
      margin-top: 1rem;
    }
  }

  & input[type=text], input[type=password] {
    width: 100%;
    height: 5rem;
    padding: 0 2rem;
  }

  & label {
    display: block;
    margin-top: 2rem;
  }

  & button.login {
    display: block;
    width: 100%;
    height: 6rem;
    margin-top: 2rem;
    padding: 0 1rem;
    background: var(--Strong);

    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    color: #fff;

    border-radius: 6px;
    border: 1px solid var(--Jet);
    box-sizing: border-box;
    vertical-align: middle;
    transition: all 0.3s;

    &:hover {
      background: var(--Stronger);
    }
  }

  & .find-buttons {
    width: 100%;
    margin-top: 2rem;

    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}

.find-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex: fit-content;

  & p {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
  }

  & input[type=text] {
    width: 26rem;
    height: 3rem;
  }

  & .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 3rem;

    & button {
      height: 3rem;
      border-radius: 6px;
      padding: 0 1rem;
      box-sizing: border-box;
      vertical-align: middle;

      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      color: #fff;
    }

    & .confirm {
      background: var(--Strong);
      border: 1px solid var(--Strong);
    }

    & .close {
      background: var(--Gray);
      border: 1px solid var(--Gray);
    }
  }
}