div.pop-detail-search {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  transition: all 0.3s;
  z-index: 10;

  div.pop-detail-window {
    position: relative;
    width: 640px;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    transform: translate(0, 100px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 12;
  }

  div.pop-detail-window > h1 {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid var(--Bright);
  }

  div.pop-detail-window > h1 span {
    font-size: 20px;
    font-weight: 700;
  }

  div.pop-detail-window div.pop-cnt {
    max-height: calc(100vh - 160px);
    margin-top: 20px;
    padding: 0 20px 20px;
    overflow-y: auto;
  }

  fieldset.advanced-search {
    div.condition {
      div.unit {
        display: flex;

        nice-select {
          width: 100px;
        }

        input[type=search] {
          flex: 1 0 auto;
          margin-left: 6px;
        }

        button.add-condition {
          width: 114px;
          background: var(--Link);
          border-color: var(--Link);
          border-radius: 6px;
          color: #ffffff;
          font-weight: 600;

          &:hover {
            background: var(--Black);
            border-color: var(--Black);
          }
        }

        button.remove-condition {
          width: 114px;
          background: var(--Gray);
          border-color: var(--Gray);

          &:hover {
            background: var(--Dark);
            border-color: var(--Dark);
          }
        }
      }

      div.unit > *:not(:first-child) + * {
        margin-left: 6px;
      }

      div.unit + div.unit {
        margin-top: 10px;
      }
    }

    div.selection {
      display: flex;
      margin-top: 20px;

      div.unit {
        display: flex;
        align-items: center;

        label {
          padding-right: 4px;
          color: var(--Gray);
          font-weight: 500;
        }
      }

      div.unit > * + * {
        margin-left: 6px;
      }

      div.unit + div.unit {
        margin-left: 20px;
      }
    }

    p.label {
      margin-top: 20px;
      color: var(--Gray);
      font-weight: 500;
    }

    p.info {
      margin-top: 10px;
      color: var(--Blue);
      font-weight: 500;
    }

    div.formula {
      margin-top: 10px;
      padding: 16px;
      background: var(--Ghost);
      border: 1px solid var(--Light);
      border-radius: 10px;
      font-weight: 600;
      line-height: 1.3;
    }

    div.button-wrap {
      display: flex;
      margin-top: 20px;

      button.search {
        width: 120px;
        height: 40px;
        background: var(--Strong);
        border-color: var(--Strong);
        color: white;
        border-radius: 6px;
        font-weight: 600;

        &:hover {
          width: 140px;
          background: var(--Stronger);
          border-color: var(--Stronger);
        }
      }

      button {
        border: 1px solid;
      }

      button.init {
        margin-left: 10px;
        width: 120px;
        background: #fff;
        border-color: var(--Gray);
        color: var(--Gray);
        border-radius: 6px;
        font-weight: 600;

        &:hover {
          width: 140px;
          border-color: var(--Black);
          color: var(--Black);
        }
      }
    }

    div.button-wrap.centro {
      justify-content: center;
    }
  }
}

div.pop-detail-search.on {
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  visibility: visible;
}


div.pop-detail-search.on div.pop-detail-window {
  transform: translate(0, 0);
  opacity: 1.0;
  visibility: visible;
}


div.pop-detail-window div.pop-cnt p.guide {
  text-align: center;
  font-size: 15px;
  line-height: 1.3;
  color: var(--Gray);
}

div.pop-detail-search button.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  background: transparent url("../assets/image/button-close.svg") center no-repeat;
  background-size: auto 12px;
  border: 0 none;
  border-radius: 50%;
  text-indent: -9999px;
}

div.pop-detail-search button.close:hover {
  background-color: var(--Jet);
  background-image: url("../assets/image/button-close-hover.svg");
}


