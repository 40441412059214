button.normal {
  display: inline-block;
  padding: 0 1rem;
  background: var(--Jet);
  border: 1px solid var(--Jet);
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  vertical-align: middle;
  transition: all 0.3s;
}

button.tacto {
  display: flex;
  width: 120px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

button.tacto.fit-content {
  width: fit-content;
}

button.cancel {
  border: 1px solid var(--KD-ID, #502ACC);
  color: var(--KD-ID, #502ACC);
}

button.confirm {
  background: var(--KD-ID, #502ACC);
  color: var(--KD-SNOW, #FFF);
}