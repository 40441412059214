.edit-project-modal {
  display: flex;
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
  }

  & .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
    }

    & .project-name {
      display: flex;
      height: 48px;
      padding: 0 16px;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--KD-MIST, #DDD);
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
    }

    & .project-description {
      display: flex;
      height: 120px;
      padding: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 130%;
    }
  }

  & .buttons {
    display: flex;
    width: 100%;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
}