.validate-input {

  width: 100%;

  &.error {
    & > input {
      border: var(--Pointer) 1px solid;
    }

    & > .message {
      margin-top: 5px;
      color: var(--Pointer);
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}