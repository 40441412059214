.editable-topic-tree-section {
  position: relative;
  height: 100%;
  overflow: scroll;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  & ul.children {
    margin-left: 34px;
  }

  & div.children {
    margin-top: 8px;
  }

  & .empty-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--KD-GRAY, #999);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }

  & .tree-node-item {
    min-width: 325px;
    max-width: 450px;
    height: 36px;
    min-height: 36px;
    padding: 4px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--KD-MIST, #DDD);
    background: var(--KD-SNOW, #FFF);


    &.on {
      border: 1px solid var(--KD-JET, #333);
      background: var(--KD-SNOW, #FFF);
    }

    & .tree-item-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      & .icon {
        width: 20px;
        height: 20px;

        &.edit {
          background-image: url("../assets/image/edit-tree-node.svg");
        }

        &.delete {
          background-image: url("../assets/image/delete-tree-node.svg");
        }

        &.add {
          background-image: url("../assets/image/add-tree-node.svg");
        }
      }
    }
  }
}