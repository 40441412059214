html, body, main, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins,
kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu,
nav, output, ruby, section, summary, time, mark, audio, video, hr, textarea, select,
input[type=text], input[type=radio], input[type=checkbox], input[type=button], input[type=image], input[type=password],
input[type=reset], input[type=submit], input[type=email], input[type=color], input[type=date], input[type=datetime],
input[type=datetime-local], input[type=month], input[type=number], input[type=range], input[type=search], input[type=tel],
input[type=time], input[type=url], input[type=week] {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, video {
  display: block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  overflow: auto;
}

caption, figcaption {
  display: none;
}

em, address {
  font-style: normal;
}

label {
  cursor: pointer;
}

legend {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}

*:focus {
  outline: 0 none;
}

a:link {
  text-decoration: none;
}

a, button, input {
  font: inherit;
  background: none;
  border: 0 none;
  cursor: pointer;
}

a {
  color: inherit;
}
