#tree {
  border: 1px solid var(--Light);
  border-radius: 1rem;
  overflow: hidden;

  & a.on {
    & span.label {
      font-weight: 700;
      color: var(--Strong);
    }
  }

  & > li + li {
    border-top: 1px solid var(--Bright);
  }

  & ul > li:not(.add) {
    padding-left: 8px;
  }

  & .toggle {
    cursor: pointer;
  }

  & p {
    position: relative;
    padding: 6px 10px 6px 20px;
    box-sizing: border-box;
  }

  & p.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    padding-left: 1.4rem;
    padding-right: 6px;
    background: var(--Ghost);

    & a {
      flex: 0 0 auto;
      height: 2.4rem;
      font-weight: 600;
      line-height: 2.4rem;
    }

    & span {
      flex: 0 0 auto;
      height: 2.4rem;
      font-weight: 600;
      line-height: 2.4rem;

      &.on {
        font-weight: 700;
        color: var(--Strong);
      }
    }

    & + ul,
    & + div {
      padding-top: 6px;
      padding-bottom: 6px;
      border-top: 1px solid var(--Bright);
    }

    & i {
      width: 2.4rem;
      height: 2.4rem;
      background: url("../assets/image/icon-tree-first.svg") center no-repeat;
      background-size: 2rem;
      transition: all 0.3s;
    }
  }

  & i.toggle {
    position: absolute;
    top: 2px;
    left: 0;
    width: 2rem;
    height: 2rem;
    background: url("../assets/image/icon-tree-last.svg") center no-repeat;
    background-size: 16px;
    transition: all 0.3s;
  }

  & li:has(ul) > p {
    padding-left: 2.4rem;

    &:before {
      display: none;
    }
  }

  & span.label {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    transition: all 0.3s;
  }

  & a:hover span.label {
    text-decoration: underline;
    text-underline-position: under;
  }

  & span.number {
    display: block;
    margin-top: 4px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--Number);
  }

  & li.on > p.head i {
    transform: rotate(90deg);
  }

  & li.on > p > i.toggle {
    transform: rotate(90deg);
  }

  & li > ul.close {
    display: none;
  }

  & span.on span.label {
    font-weight: 700;
    color: var(--Strong);
  }

  & p:not(.head):before {
    position: absolute;
    top: 10px;
    left: 8px;
    width: 4px;
    height: 4px;
    background: var(--Gray);
    content: "";
  }

  & p:not(.head):has(a.on):before {
    top: 7px;
    height: 11px;
    background: var(--Strong);
  }

  & ul.not-tree {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 4px !important;

    & li {
      flex: 0 0 auto;
      margin: 2px;
      padding: 0;

      & a {
        display: inline-block;
        height: 3rem;
        padding: 6px 8px;
        border: 1px solid var(--Bright);
        border-radius: 4px;
        font-size: 1.4rem;
        font-weight: 500;
        box-sizing: border-box;
        transition: all 0.3s;

        &.on {
          background: var(--Strong);
          border-color: var(--Strong);
          color: #fff;
        }

        &:not(.on):hover {
          background-color: var(--Ghost);
          border-color: var(--Jet);
          color: var(--Jet);
        }
      }
    }
  }
}
