h2.neo-home {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -0.05em;
}

section.neo-home-welcome {
  margin-top: -80px;
  padding: 256px 0 96px;
  background: url("../assets/image/neo-bg-home-welcome.png") no-repeat center / cover;
  color: var(--NeoSnow);
}

section.neo-home-welcome div.neo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.neo-search {
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

div.neo-search fieldset {
  display: flex;
  align-items: center;
  height: 48px;
  padding-right: 24px;
  border: 1px solid var(--NeoSnow);
  border-radius: 999px;
  box-sizing: border-box;

  & div.nice-select {
    width: 110px;
    height: 100%;
    padding-left: 24px;
    background: transparent;
    border: 0 none;
    font-size: 15px;
    font-weight: 500;
    line-height: 46px;
    color: var(--NeoSnow);
  }
}

div.neo-search fieldset div.nice-select ul.list {
  padding: 8px 0;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 24px;
  color: var(--NeoCloud);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

div.neo-search fieldset div.nice-select ul.list li.option {
  line-height: 36px;
  min-height: 36px;
  padding: 0 29px 0 23px;
}

div.neo-search fieldset div.nice-select ul.list li.option:hover,
div.neo-search fieldset div.nice-select ul.list li.option.focus,
div.neo-search fieldset div.nice-select ul.list li.option.selected.focus {
  background: transparent;
  color: var(--NeoSnow);
}

div.neo-search fieldset div.nice-select ul.list li.option.selected {
  font-weight: 400;
}

div.neo-search fieldset input[type=text] {
  width: calc(100% - 134px);
  height: 100%;
  background: transparent;
  border: 0 none;
  font-weight: 600;
  color: var(--NeoSnow);
}

div.neo-search fieldset input[type=text]::placeholder {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: var(--NeoSnow) !important;
}

div.neo-search fieldset input[type=text]:focus::placeholder {
  color: transparent !important;
}

div.neo-search fieldset button.clear {
  width: 15px;
  height: 15px;
  padding: 0;
  margin-right: 5px;
  background: transparent url("../assets/image/button-close-hover.svg") no-repeat center;
  border: 0 none;
}

div.neo-search fieldset button.search {
  width: 24px;
  height: 24px;
  padding: 0;
  background: transparent url("../assets/image/neo-button-24-search-w.svg") no-repeat center;
  border: 0 none;
}

div.neo-intro {
  margin-top: 48px;
  text-align: center;
}

ul.neo-summary {
  display: flex;
  justify-content: center;
  gap: 48px;
  margin-top: 48px;
  text-align: center;

  & dt {
    font-size: 14px;
    font-weight: 500;
  }

  & dd {
    margin-top: 12px;
    font-size: 24px;
    font-weight: 300;
    color: var(--NeoHighlight);
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    & li {
      width: calc(50% - 44px);
    }
  }
}

div.neo-intro hgroup {
  padding-bottom: 84px;
  background: url("../assets/image/neo-symbol.svg") no-repeat center bottom;
}

div.neo-intro hgroup h2 {
  font-size: 28px;
  font-weight: 900;
}

div.neo-intro hgroup span {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

div.neo-intro p {
  margin-top: 16px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.6);
}

div.neo-intro p + p {
  margin-top: 8px;
  font-weight: 500;
  color: var(--NeoSnow);
}

section.neo-home-news {
  padding: 96px 0 272px;
  background: url("../assets/image/neo-bg-home-news-line.png") no-repeat center bottom;
}

ul.neo-news {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 32px;

  & a {
    flex: 1 1 25%;
  }
}

ul.neo-news dt {
  font-size: 18px;
  font-weight: 20;
  font-weight: 700;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

ul.neo-news dd.text {
  margin-top: 12px;
  line-height: 1.5;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

ul.neo-news dd.sub {
  margin-top: 8px;
  font-size: 14px;
}

a.neo-news-more {
  display: flex;
  align-items: center;
  width: 128px;
  height: 48px;
  margin: 32px auto 0;
  padding: 0 24px;
  background: url("../assets/image/neo-icon-24-arrow.svg") no-repeat right 16px center;
  border: 1px solid var(--NeoJet);
  border-radius: 999px;
  transition: all 0.3s;
}

a.neo-news-more:hover {
  width: 144px;
}

section.neo-home-journal {
  padding: 96px 0 104px;
  background: var(--NeoGhost);
}

@media all and (max-width: 768px) {
  div.neo-container {
    width: 100%;
    padding: 0 20px;
  }

  footer {
    min-width: unset;
    padding: 40px 20px;
    text-align: left;
  }
  footer p {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  footer ul.menu {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  main {
    min-width: unset;
  }


  h2.neo-home {
    text-align: left;
  }

  ul.neo-summary {
    flex-wrap: wrap;
    gap: 24px;
    text-align: left;
  }
  ul.neo-summary li {
    width: calc(50% - 44px);
  }

  section.neo-home-news {
    padding: 48px 0 136px;
    background-position: right 20px bottom;
    background-size: auto 108px;
  }
  ul.neo-news {
    flex-direction: column;
  }
  ul.neo-news dd.text {
    -webkit-line-clamp: 2;
  }
  a.neo-news-more {
    margin: 32px 0 0;
  }

  section.neo-home-journal {
    padding: 48px 0 64px;
  }

}

@media all and (max-width: 480px) {
  div.neo-search fieldset input[type=text]::placeholder {
    color: transparent !important;
  }
}
