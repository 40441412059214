ul.my-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

ul.my-form li {
  flex: 0 0 calc(50% - 10px);
  margin: 10px 0;
}

ul.my-form li label {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  color: var(--Gray);
}

ul.my-form li input {
  width: 100%;
  height: 40px;
}

ul.my-form li .nice-select,
ul.my-form li .nice-select .list {
  width: 100%;
}
