section.home-btm {
  padding: 80px 0 120px;
}

section.home-btm ul.doc-tab {
  display: flex;
  justify-content: center;
}

section.home-btm ul.doc-tab li {
  position: relative;
  padding-bottom: 12px;
  font-size: 26px;
  font-weight: 300;
  color: var(--Gray);
  cursor: pointer;
  transition: all 0.3s;
}

section.home-btm ul.doc-tab li:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 32px;
  height: 2px;
  margin-left: -16px;
  background: var(--Jet);
  content: "";
  transform: scaleX(0);
  transition: all 0.3s;
}

section.home-btm ul.doc-tab li:hover {
  color: var(--Jet);
}

section.home-btm ul.doc-tab li.on {
  font-weight: 800;
  color: var(--Jet);
}

section.home-btm ul.doc-tab li.on:after {
  transform: scaleX(1.0);
}

section.home-btm ul.doc-tab li + li {
  margin-left: 20px;
}

section.home-btm div.doc-wrap {
  position: relative;
  height: 205px;
  margin-top: 20px;
  overflow: hidden;
}

section.home-btm div.doc-list {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.3s;
}

section.home-btm div.doc-list.on {
  opacity: 1.0;
  visibility: visible;
  transform: translateY(0);
}

section.home-btm div.doc-list div.owl-stage {
  padding: 20px 15px;
}

section.home-btm div.doc-list div.doc {
  width: 120px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s;
}

section.home-btm div.doc-list div.doc + div.doc {
  margin-left: 20px;
}

section.home-btm div.doc-list div.doc img {
  filter: grayscale(100%);
  transition: all 0.3s;
}

section.home-btm div.doc-list div.doc:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
}

section.home-btm div.doc-list div.doc:hover img {
  filter: grayscale(0);
}

ul.book-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;
}

ul.book-list > li {
  flex: 0 0 120px;
  margin: 20px;
}

ul.book-list dl {
}

ul.book-list dl dt {
  border-radius: 6px;
  overflow: hidden;
  font-size: 0;
  transition: all 0.3s;
}

ul.book-list dl dd {
  margin-top: 16px;
  font-size: 15px;
  line-height: 1.3;
  transition: all 0.3s;
}

ul.book-list dl:hover dt {
  box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
}

ul.book-list dl:hover dd {
  color: var(--Strong);
  text-decoration: underline;
  text-underline-position: under;
}

ul.neo-book-tab {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 32px;
}

ul.neo-book-tab li {
  position: relative;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  color: var(--NeoGray);
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

ul.neo-book-tab li:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--NeoJet);
  content: "";
  transform: scaleX(0);
  transition: all 0.3s;
}

ul.neo-book-tab li:hover {
  color: var(--NeoJet);
}

ul.neo-book-tab li.on {
  font-weight: 700;
  color: var(--NeoJet);
}

ul.neo-book-tab li.on:after {
  transform: scaleX(1.0);
}

div.neo-book-wrap {
  position: relative;
  height: 205px;
  margin-top: 8px;
  overflow: hidden;
}

div.neo-book-wrap div.book-list {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.3s;
}

div.neo-book-wrap div.book-list.on {
  opacity: 1.0;
  visibility: visible;
  transform: translateY(0);
}

div.neo-book-wrap div.book-list div.owl-stage {
  padding: 24px 0;
}

div.neo-book-wrap div.book-list div.book {
  width: 120px;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s;
}

div.neo-book-wrap div.book-list div.book:hover {
  transform: translateY(-4px);
  box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
}

div.neo-book-wrap div.book-list div.book + div.book {
  margin-left: 20px;
}

@media all and (max-width: 768px) {
  ul.neo-book-tab {
    justify-content: flex-start;
    overflow-x: scroll;
  }
}