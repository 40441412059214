.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  & .search {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & input {
      width: 360px;
      height: 40px;
      padding: 0 12px;
    }

    & button {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      background-image: url("../assets/image/search.svg");
    }
  }
}
