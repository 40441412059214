.topic-tree-management-modal {
  display: flex;
  width: 1200px;
  height: 800px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;

  & .tree-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
      line-height: 100%;
    }

    & .text-box {
      color: var(--KD-JET, #333);
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      align-self: stretch;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      border-radius: 12px;
      border: 1px solid var(--KD-MIST, #DDD);
    }
  }

  & .management-sections {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 574px;
    align-items: flex-start;
    gap: 12px;

    & .left-section {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);

      & .section-top {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & .title {
          color: var(--KD-JET, #333);
          font-size: 16px;
          font-weight: 700;
          line-height: 100%;
        }

        & button {
          display: flex;
          height: 24px;
          padding: 0 12px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: var(--KD-ID, #502ACC);
          color: var(--KD-SNOW, #FFF);
          font-size: 12px;
          font-weight: 700;
          line-height: 100%;
        }
      }
    }

    & .right-section {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);
    }
  }

  & .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    & button.button {
      height: 40px;
      padding: 0 16px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      background: var(--KD-JET, #333);
      color: var(--KD-SNOW, #FFF);
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;

      &.white {
        border: 1px solid var(--KD-JET, #333);
        background: var(--KD-JET, #FFF);
        color: var(--KD-JET, #333);
      }
    }
  }
}