main {
  position: relative;
  overflow: scroll;

  & > div.container {
    min-height: calc(100vh - 477px);
    padding-top: 15rem;
    padding-bottom: 12rem;
  }

  & ol.location {
    display: flex;
    align-items: center;
    gap: 2rem;

    & li {
      position: relative;
      font-size: 1.4rem;

      &:first-child {
        flex: 0 0 2rem;
        height: 2rem;

        & .icon-home {
          display: block;
          width: 100%;
          height: 100%;
          background: url("../assets/image/icon-home.svg") center no-repeat;
          background-size: auto;
          text-indent: -9999px;
        }
      }

      & + li:before {
        position: absolute;
        left: -1.8rem;
        width: 1.6rem;
        height: 1.6rem;
        background: url("../assets/image/icon-loc-arrow.svg") center no-repeat;
        background-size: 1.4rem;
        content: "";
      }

      & span {
        font-weight: 500;
        color: var(--Gray);
        transition: all 0.3s;

        &:hover {
          color: var(--Jet);
          text-decoration: underline;
          text-underline-position: under;
        }
      }

      & strong {
        font-weight: 700;
        color: var(--Strong);
      }
    }

    & + * {
      margin-top: 40px;
    }
  }

  & h1.head {
    font-size: 3.6rem;
    font-weight: 700;
  }

  & h1.head-pointer {
    font-size: 3.6rem;
    font-weight: 700;
    cursor: pointer;
  }

  & div.content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 4rem;
  }

  & aside.aside {
    flex: 0 0 24rem;
  }

  & section.content {
    flex: 0 0 92rem;
  }

  & a.ad-bnr {
    display: block;
    width: 100%;
    margin-top: 1rem;
    border-radius: 1rem;
    font-size: 0;
    line-height: 0;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }

    &:hover img {
      transform: scale(1.06);
    }

    & img {
      transition: all 0.3s;
    }
  }

  @media (max-width: 768px) {
    min-width: unset;

    & > div.container {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      margin-top: 150px;
    }

    & ol.location {
      display: none;
    }

    & ol.location + div.content-wrap {
      margin: 0;
    }

    &h1.head,
    hgroup.journal-head h1,
    h1.author-head span.ko {
      font-size: 24px;
      margin: 0;
    }

    & div {
      &.result-summary {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        & fieldset.sort {
          flex-wrap: wrap;
          gap: 8px;

          & div.nice-select, button {
            margin: 0;
          }
        }

        p.sum {
          font-size: 18px;
        }
      }

      &.content-wrap {
        flex-direction: column;
        margin-top: 20px;
        gap: 20px;

        &.reverse {
          flex-direction: column-reverse;
        }
      }

      &.filter-letter {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
        gap: 8px;

        & button {
          margin: 0;
        }
      }

      &.table-wrapper {
        width: 100%;
        overflow-x: scroll;
      }

      &.pagination {
        justify-content: flex-start;
        max-width: 100%;
        margin-top: 20px;
        overflow-x: scroll;
      }
    }

    & aside.aside {
      flex: unset;
      width: 100%;
    }

    & section.content {
      flex: unset;
      width: 100%;
    }

    & ul {
      &.thesis-list {
        hgroup {
          h1 {
            font-size: 18px;
          }
        }

        div.info {
          dl {
            flex-direction: column;
            gap: 8px;

            dt {
              display: none;
            }

            dd {
              flex: unset;
              margin: 0;
            }
          }
        }
      }

      & > li .box {
        padding: 8px;
      }

      &.journal-list {
        h1 {
          font-size: 18px;
        }

        p.desc {
          flex-wrap: wrap;
          gap: 6px;

          span + span {
            margin: 0;
          }
        }
      }

      &.author-list {
        h1 {
          span.ko {
            font-size: 18px;
          }
        }

        p.title span.main {
          font-size: 16px;
        }
      }

      &.table-type {
        position: relative;
        width: 100%;

        & > li {
          border: 1px solid var(--Bright);
          border-radius: 10px;
          overflow: hidden;
          transition: all 0.3s;
        }

        & > li + li {
          margin-top: 20px;
        }

        div.info {
          position: relative;
          flex-direction: column;
          gap: 8px;
          padding: 10px 10px 50px;
        }

        dl {
          text-align: left;
          box-sizing: border-box;
        }

        dt, dd {
          height: auto;
          padding: 0;
          line-height: 1.3;
          border-bottom: 0 none;
          max-width: 100%;
          box-sizing: border-box;
          transition: all 0.3s;
        }

        dt {
          display: block;
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 1.0;
          color: var(--Gray);
          border: 0 none;
        }

        & > li:first-child dt {
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 1.0;
        }
      }

      &.table-type:before {
        display: none;
      }

      &.institution-list {
        font-size: 20px;

        & dl {
          &.name-ko, .name-en, .button, .region {
            padding: unset;
            flex: unset;
          }

          &.region {
            flex: 0 0;
          }

          &.amount {
            flex: 0 0;
          }

          &.amount dd {
            text-align: left;
          }

          &.button {
            position: absolute;
            bottom: 10px;

            & dt {
              display: none;
            }
          }
        }

        & div.journal {
          border: 0 none;

          & ul {
            padding: 8px;
          }
        }
      }
    }

    & article {
      &.thesis-detail {
        hgroup {
          h1 {
            font-size: 18px;
          }
        }

        div.func {
          flex-wrap: wrap;
          gap: 8px;
        }

        div.func a + a {
          margin: 0;
        }

        div.info {
          dl {
            flex-direction: column;
            gap: 8px;

            dt {
              display: none;
            }

            dd {
              flex: unset;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
