
ul.bbs-info {
  display: flex;
  align-items: center;
}

ul.bbs-info li {
  font-size: 14px;
  font-weight: 500;
  color: var(--Gray);
}

ul.bbs-info li + li {
  margin-left: 16px;
}

article.bbs-view {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid var(--Bright);
  border-radius: 10px;
  box-sizing: border-box;
}

article.bbs-view p {
  font-size: 16px;
  line-height: 1.3;
}

article.bbs-view p + p {
  margin-top: 20px;
}

div.bbs-comment-box {
  margin-top: 20px;
  padding: 10px;
  background: #ECEBFF;
  border-radius: 6px;
}

p.bbs-comment {
  margin-top: 20px;
}
