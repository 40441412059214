
main.member section p.announce.password-01 {
  background-image: url("../assets/image/icon-password-01.svg");
}

main.member section p.announce.password-02 {
  background-image: url("../assets/image/icon-password-02.svg");
}

ul.my-form.full > li p.alert {
  margin-top: 5px;
  color: var(--Pointer);
  font-size: 1.4rem;
  font-weight: 600;
}
