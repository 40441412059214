ul.institution-list dl.name-ko {
  flex: 1;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.institution-list dl.button {
  flex: 0 0 80px;
}

ul.institution-list dl.region {
  flex: 0 0 100px;
}

ul.institution-list dl.amount {
  flex: 0 0 130px;
}

ul.institution-list dl.name-ko dd,
ul.institution-list dl.name-en dd {
  text-align: left;
}

ul.institution-list dl.button dd {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.institution-list dl.amount dd {
  text-align: center;
}

ul.institution-list button.toggle {
  display: block;
  width: 32px;
  height: 32px;
  margin: auto;
  background: #fff url("../assets/image/icon-link-journal.svg") center no-repeat;
  background-size: 18px;
  border: 1px solid var(--Gray);
  border-radius: 50%;
  text-indent: -9999px;
}

ul.institution-list button.toggle:hover {
  background-color: var(--Strong);
  background-image: url("../assets/image/icon-link-journal-hover.svg");
  border-color: var(--Strong);
}

ul.institution-list div.journal {
  display: none;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: var(--Bright);
}

ul.institution-list div.journal.on {
  display: block;
}

ul.institution-list div.journal ul {
  padding: 20px;
  background: var(--Ghost);
}

ul.institution-list div.journal li + li {
  margin-top: 8px;
}

ul.institution-list div.journal li span {
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 0 10px 0 30px;
  background-color: #fff;
  border: 1px solid var(--Bright);
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  transition: all 0.3s;
}

ul.institution-list div.journal li span:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: url("../assets/image/icon-link-journal.svg") center no-repeat;
  background-size: 18px;
  content: "";
}

ul.institution-list div.journal li span:hover {
  padding: 0 16px 0 36px;
  border-color: var(--Strong);
  color: var(--Strong);
}
