ul.my-form li div.duplicate-check {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & > button {
    height: 4rem;
  }

  & .duplicate-check-input {
    width: 100%;

    &.error {
      & input {
        border: var(--Pointer) 1px solid;
      }

      & > .message {
        margin-top: 5px;
        color: var(--Pointer);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    &.usable {

      & > .message {
        margin-top: 5px;
        color: var(--Blue);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
}

ul.my-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

ul.my-form > li {
  flex: 0 0 calc(50% - 10px);
  margin: 10px 0;
}

ul.my-form > li label {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  color: var(--Gray);
}

ul.my-form li label span.required {
  font-size: 12px;
  font-weight: 600;
  color: var(--Pointer);
}

ul.my-form > li input {
  width: 100%;
  height: 50px;
}

ul.my-form > li .nice-select {
  width: 100%;
  height: 50px;
  line-height: 48px;
}

ul.my-form > li .nice-select .list {
  width: 100%;
}

ul.my-form > li div.with-btn {
  position: relative;
}

ul.my-form > li div.with-btn input[type=text] {
  padding-right: 90px;
}

ul.my-form > li div.with-btn button {
  position: absolute;
  right: 12px;
  top: 7px;
  height: 26px;
  border-radius: 4px;
  font-size: 13px;
}

ul.my-form > li div.with-btn button:hover {
  background: var(--Strong);
  border-color: var(--Strong);
}

ul.my-form > li div.check-group {
  display: flex;
  align-items: center;
  min-height: 40px;
}

ul.my-form > li div.check-group label + label {
  margin-left: 10px;
}

ul.my-form > li p.guide {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--Cloud);
}

ul.my-form > li p.alert {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--Alert);
}

ul.my-form.full {
  margin-top: 30px;
}

ul.my-form.full > li {
  flex-basis: 100%;
  margin: 0;
}

ul.my-form.full > li + li {
  margin-top: 16px;
}

