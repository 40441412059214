h1.author-head {

  & span.ko {
    font-size: 3rem;
    font-weight: 700;
  }

  & span.en {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--Cloud);
  }

  & + * {
    margin-top: 2rem;
  }
}
