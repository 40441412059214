.move-topic-material-modal {
  display: flex;
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
  }

  & .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
      line-height: 100%;
    }

    & .nice-select {
      width: 100%;

      & .list {
        width: 100%;
      }
    }
  }

  & .topic-tree {
    position: relative;
    overflow: scroll;
    height: 100%;
    max-height: 476px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    & ul.children {
      margin-left: 34px;
    }

    & div.children {
      margin-top: 8px;
    }

    & .tree-node-item {
      min-width: 325px;
      max-width: 450px;
      height: 36px;
      min-height: 36px;
      padding: 4px 16px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--KD-MIST, #DDD);
      background: var(--KD-SNOW, #FFF);

      &.on {
        border: 1px solid var(--KD-JET, #333);
        background: var(--KD-SNOW, #FFF);
      }
    }
  }

  & .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }
}