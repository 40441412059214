
#tree-check {
  & ul.limit-height {
    max-height: 200px;
    overflow-y: scroll;
  }

  & > li {
    border: 1px solid var(--Light);
    border-radius: 10px;
    overflow: hidden;
  }

  & > li + li {
    margin-top: 10px;
  }

  & ul > li {
    padding: 0 8px;
  }

  & .toggle {
    cursor: pointer;
  }

  & p {
    position: relative;
    padding: 6px 20px 6px 6px;
    box-sizing: border-box;
  }

  & li:has(ul) > p:before {
    display: none;
  }

  & p.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-left: 14px;
    padding-right: 6px;
    background: var(--Ghost);
  }

  & p.head span {
    font-weight: 600;
  }

  & p.head i {
    width: 24px;
    height: 24px;
    background: url("../assets/image/icon-tree-first.svg") center no-repeat;
    background-size: 20px;
    transition: all 0.3s;
  }

  & p.head + ul {
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid var(--Bright);
    transition: 0.3s;
  }

  & li > ul.close {
    display: none;
  }

  & i.toggle {
    position: absolute;
    top: 4px;
    right: 0;
    width: 20px;
    height: 20px;
    background: url("../assets/image/icon-tree-last.svg") center no-repeat;
    background-size: 16px;
    transition: all 0.3s;
  }

  & span.label {
  }

  & span.label::before {
    top: 0;
    margin-top: 0;
  }

  & span.label::after {
    top: 0;
    margin-top: 4px;
  }

  & span.label i {
    display: block;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.0;
    color: var(--Dark);
    transition: all 0.3s;
  }

  & span.label i.number {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
  }

  & input[type=checkbox]:checked + span.label i.number {
    font-weight: 400;
    color: var(--Number);
  }

  & button.list-toggle {
    display: block;
    width: 100%;
    height: 28px;
    background: var(--Ghost);
    border-color: var(--Light);
    border-width: 1px 0 0;
    border-radius: 0;
    font-size: 13px;
    font-weight: 500;
    color: var(--Gray);
  }

  & li.on > p.head i {
    transform: rotate(90deg);
  }

  & li.on > p > i.toggle {
    transform: rotate(90deg);
  }
}
