.middle-section {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  & h2 {
    color: var(--KD-ID, #502ACC);
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
  }

  & .list-button {
    display: flex;
    height: 24px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--KD-SNOW, #FFF);
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    border-radius: 6px;
    background: var(--KD-JET, #333);

    & .list-icon {
      width: 14px;
      height: 14px;
      background-image: url("../assets/image/list-icon.svg");
    }
  }
}

.content-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  & .left-section {
    display: flex;
    width: 340px;
    min-width: 340px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    & .side-topic-tree {
      position: relative;
      width: auto;
      padding: 12px;
      min-height: 430px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);

      & .topic-tree-name {
        display: flex;
        height: 28px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        color: var(--KD-JET, #333);
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
      }

      & .icon {
        width: 22px;
        height: 22px;

        &.tree {
          background-image: url("../assets/image/tree-black.svg");
        }
      }

      & .topic-tree-section {

        & .tree-node-item {
          display: flex;
          height: 20px;
          align-items: center;
          gap: 4px;
          color: var(--KD-GRAY, #999);
          font-size: 14px;
          font-weight: 500;
          line-height: 100%;

          &.on {
            color: var(--KD-JET, #333);
          }

          & .icon.open-folder {
            background-image: url("../assets/image/open-folder-grey.svg");
            background-repeat: no-repeat;
            background-position: center;

            &.on {
              background-image: url("../assets/image/open-folder-grey-active.svg");
            }
          }
        }
      }
    }

    & .materials {
      display: flex;
      width: auto;
      padding: 12px;
      min-height: 430px;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);

      & .material-detail {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        & .title {
          color: var(--KD-JET, #333);
          font-size: 20px;
          font-weight: 700;
          line-height: 130%;
        }

        & .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;

          & .items {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 12px;
          }

          & .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            & .label {
              color: var(--KD-GRAY, #999);
              font-size: 13px;
              font-weight: 600;
              line-height: 100%;
            }

            & input[type='text'] {
              width: 100%;
              display: flex;
              height: 40px;
              padding: 0 16px;
              align-items: center;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid var(--KD-MIST, #DDD);
              background: var(--KD-SNOW, #FFF);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: var(--KD-JET, #333);
              text-overflow: ellipsis;
              font-size: 16px;
              font-weight: 500;
              line-height: 100%;
            }

            & textarea {
              display: flex;
              height: 110px;
              padding: 12px 16px;
              align-items: flex-start;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid var(--KD-MIST, #DDD);
              background: var(--KD-SNOW, #FFF);
              color: var(--KD-JET, #333);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
            }
          }

          & .memo-section {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            border-radius: 12px;
          }
        }

        & .buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
        }
      }

      & .material-list {
        width: 100%;
      }

      & .project-item-wrap {
        position: relative;
        padding: 12px;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        border-bottom: 1px solid var(--KD-MIST, #DDD);

        & .material {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;

          & .title {
            color: var(--KD-JET, #333);
            font-size: 16px;
            font-weight: 600;
            line-height: 130%;
          }

          & .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          & .left-container {
            display: flex;
            justify-items: left;
            align-items: center;
            gap: 8px;
            white-space: nowrap;
            max-width: 600px;

            & div:last-child {
              overflow: hidden;
              white-space: nowrap;
            }

            & .divider {
              background: var(--KD-SILVER, #BBB);
              width: 1px;
              height: 10px;
            }
          }

          & .attachment-memo-container {
            display: flex;
            align-items: center;
            gap: 8px;

            & .memo-title {
              color: var(--KD-DIM, #777);
              font-size: 14px;
              font-weight: 600;
              line-height: 100%;
            }

            & .updated-at {
              color: var(--KD-GRAY, #999);
              font-size: 13px;
              font-weight: 500;
              line-height: 100%;
            }

            & button {
              display: flex;
              height: 24px;
              padding: 0 8px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 6px;
              border: 1px solid var(--KD-SILVER, #BBB);
              background: var(--KD-SNOW, #FFF);
              color: var(--KD-SILVER, #BBB);
              font-size: 12px;
              font-weight: 600;
              line-height: 100%;

              & .icon {
                width: 14px;
                height: 14px;

                &.memo {
                  background-image: url("../assets/image/memo-button.svg");
                }

                &.attachment {
                  background-image: url("../assets/image/attachment-button.svg");
                }
              }

              &.existed {
                background: var(--KD-JET, #333);
                color: var(--KD-SNOW, #FFF);

                & .icon {

                  &.memo {
                    background-image: url("../assets/image/memo-button-existed.svg");
                  }

                  &.attachment {
                    background-image: url("../assets/image/attachment-button-existed.svg");
                  }
                }
              }
            }
          }

          & .updated-at {
            color: var(--KD-GRAY, #999);
            font-size: 12px;
            font-weight: 600;
            line-height: 100%;
          }
        }
      }
    }
  }

  .divider-line {
    position: relative;
    width: 1px;
    min-width: 1px;
    height: 100vh;
    margin: 0 12px;
    background: var(--KD-JET, #333);

    & .divider-thumb {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 20px;
      height: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: var(--KD-JET, #333);
      background-image: url("../assets/image/divider-thumb.svg");
    }
  }

  .right-section {
    display: flex;
    width: 820px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    & .text-editor {
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);

      & .top-section {
        display: flex;
        justify-content: space-between;

        & .section-title {
          color: var(--KD-JET, #333);
          font-size: 16px;
          font-weight: 700;
          line-height: 100%;
        }

        & .suggestion-toggle {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 13px;
          font-weight: 700;
          line-height: 100%;
          cursor: pointer;
          color: var(--KD-JET, #333);

          input[type="checkbox"] {
            display: flex;
            width: 32px;
            height: 16px;
            padding: 2px;
            justify-content: flex-end;
            align-items: center;
            border-radius: 8px;
            appearance: none;
            position: relative;
            border: 1px solid gray;
            background: var(--KD-ID, #502ACC);
            opacity: 0.5;
          }

          input[type="checkbox"]::before {
            content: "";
            position: absolute;
            left: 1px;
            width: 12px;
            height: 12px;
            flex-shrink: 0;
            border-radius: 50%;
            transition: left 250ms linear;
            background-color: var(--KD-SNOW, #FFF);
            opacity: 1;
          }

          input[type="checkbox"]:checked {
            background: var(--KD-ID, #502ACC);
            opacity: 1;
          }

          input[type="checkbox"]:checked::before {
            background-color: white;
            left: 16px;
            opacity: 1;
          }
        }
      }

      & .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        & .label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: var(--KD-GRAY, #999);
          font-size: 13px;
          font-weight: 600;
          line-height: 100%;
        }

        input[type="text"] {
          display: flex;
          height: 40px;
          padding: 0 16px;
          align-items: center;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--KD-MIST, #DDD);
          background: var(--KD-SNOW, #FFF);
        }

        textarea {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid var(--KD-MIST, #DDD);
          background: var(--KD-SNOW, #FFF);
          height: 100%;
          min-height: 320px;
          max-height: 500px;
          resize: none;
        }
      }

      & .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        button {
          display: flex;
          width: 120px;
          height: 48px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 700;
          line-height: 100%;
        }

        .save {
          border: 1px solid var(--KD-ID, #502ACC);
          color: var(--KD-ID, #502ACC);
        }

        .done {
          background: var(--KD-ID, #502ACC);
          border: 1px solid var(--KD-ID, #502ACC);
          color: var(--KD-SNOW, #FFF);
        }
      }
    }

    & .suggestions {
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-self: stretch;
      gap: 12px;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);

      & .top-section {
        display: flex;
        justify-content: space-between;

        & .section-title {
          color: var(--KD-JET, #333);
          font-size: 16px;
          font-weight: 700;
          line-height: 100%;
        }
      }

      & .select-box-section {
        display: flex;
        align-items: flex-end;
        align-content: flex-end;
        gap: 8px;
        align-self: stretch;
        flex-wrap: wrap;

        & .select-box-wrap {
          display: flex;
          min-width: 100px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;

          & .label {
            color: var(--KD-GRAY, #999);
            font-size: 12px;
            font-weight: 600;
            line-height: 100%;
          }

          & .nice-select {
            width: 100%;
          }
        }
      }

      & .suggestion-paragraphs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        & .suggestion-item {
          display: flex;
          padding: 8px;
          justify-content: space-between;
          align-self: stretch;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid var(--KD-MIST, #DDD);
          background: var(--KD-SNOW, #FFF);

          &.on {
            border: 1px solid var(--KD-JET, #333);
          }
        }
      }
    }

    & .suggestion-sentence {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      align-self: stretch;

      & .sentence {
        padding: 8px;
        border-radius: 8px;
        background: var(--KD-POINT, #FFB800);
        color: var(--KD-JET, #333);
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        align-self: stretch;
      }

      & .buttons {
        margin-left: 10px;
        display: inline-flex;
        align-items: center;
        gap: 8px;

        & button {
          display: flex;
          width: fit-content;
          min-width: fit-content;
          height: 24px;
          padding: 0 8px;
          border-radius: 6px;
          background: var(--KD-JET, #333);
          justify-content: center;
          align-items: center;
          gap: 4px;
          color: var(--KD-SNOW, #FFF);
          font-size: 12px;
          font-weight: 600;
          line-height: 100%;

          & .icon {
            width: 14px;
            height: 14px;

            &.copy {
              background-image: url("../assets/image/sentence-copy.svg");
            }

            &.paraphrasing {
              background-image: url("../assets/image/paraphrasing.svg");
            }
          }
        }
      }
    }
  }

  & .left-section, .right-section {
    & .search {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & input {
        width: 100%;
        height: 40px;
        padding: 0 42px 0 12px;
      }

      & button {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        background-image: url("../assets/image/search.svg");
      }
    }
  }
}
