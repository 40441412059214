.modify-topic-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
  }

  & .modify-topic-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
  }

  & .modify-topic-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
      line-height: 100%;
    }

    & .text-box {
      display: flex;
      height: 40px;
      padding: 0 16px;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--KD-MIST, #DDD);
      background: var(--KD-SNOW, #FFF);
    }

    & textarea.text-box {
      height: 200px;
      padding: 16px;
    }
  }
}