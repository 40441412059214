.topic-tree-section {
  margin-top: 12px;
  margin-left: 4px;
  display: flex;
  flex-direction: column;

  & ul.children {
    margin-left: 12px;
  }

  & div.children {
    margin-top: 8px;
  }

  & .tree-node-item {
    padding: 0 0 0 8px;
    min-height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    border-radius: 8px;

    & .topic-name {
      flex: 1;
    }

    &.on {
      color: var(--KD-FL, #00FFF0);
    }

    & .icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.collect {
        background-color: var(--KD-FL, #00FFF0);
        border-radius: 50%;
        background-image: url("../assets/image/collect-button.svg");
        background-repeat: no-repeat;
        background-position: center;
      }

      &.open-folder {
        background-image: url("../assets/image/open-folder.svg");
        background-repeat: no-repeat;
        background-position: center;

        &.on {
          background-image: url("../assets/image/open-folder-active.svg");
        }
      }

      &.drag-handle {
        cursor: pointer;
        background-image: url("../assets/image/draggable.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}