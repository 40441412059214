.material-details-modal {
  width: 900px;
  max-height: 80vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .title {
    display: flex;
    gap: 5px;
    align-items: center;
    color: var(--KD-JET, #333);
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;

    & a.article-link {
      display: block;
      width: 14px;
      height: 14px;
      background-size: 14px;
      background-image: url("../assets/image/external-link.svg");
    }
  }

  & .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    & .items {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }

    & .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      & .label {
        color: var(--KD-GRAY, #999);
        font-size: 13px;
        font-weight: 600;
        line-height: 100%;
      }

      & input[type='text'] {
        width: 100%;
        display: flex;
        height: 40px;
        padding: 0 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: var(--KD-JET, #333);
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }

      & textarea {
        display: flex;
        height: 110px;
        padding: 12px 16px;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);
        color: var(--KD-JET, #333);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
    }

    & .memo-section {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);
    }
  }

  & .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }
}