
main.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 60px 0;
  box-sizing: border-box;
}

main.member hgroup {
  cursor: pointer;
}

main.member hgroup h1 {
  width: 180px;
  height: 30px;
  background: url("../assets/image/h1-logo.svg") center no-repeat;
  background-size: 100%;
  text-indent: -9999px;
}

main.member hgroup h2 {
  margin-top: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--Gray);
}

main.member section {
  width: 400px;
  margin-top: 40px;
}

main.member section h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

main.member section p.announce {
  margin-top: 30px;
  padding-top: 54px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 44px;
  text-align: center;
  line-height: 1.3;
}

main.member section p.announce.join-01 {
  background-image: url("../assets/image/icon-join-01.svg");
}

main.member section p.announce.join-02 {
  background-image: url("../assets/image/icon-join-02.svg");
}

main.member section div.add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

main.member section div.add ul {
  display: flex;
}

main.member section div.add ul li + li {
  margin-left: 12px;
}

main.member section div.add label span,
main.member section div.add ul a {
  font-size: 14px;
}

main.member section .buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

main.member section button.normal {
  height: 4rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

main.member section button.strong {
  display: block;
  width: 100%;
  height: 60px;
  margin-top: 30px;
  background: var(--Strong);
  border: 1px solid var(--Strong);
  font-size: 18px;
}

main.member section button.strong.email {
  background: var(--Strong) url("../assets/image/icon-email.svg") no-repeat left 14px center / 24px;
}

main.member section button.strong:hover {
  background-color: var(--Stronger);
  background-position: left 22px center;
  border-color: var(--Stronger);
}

main.member section p.hr {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

main.member section p.hr::before {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  background: var(--Light);
  content: "";
  z-index: 0;
}

main.member section p.hr span {
  padding: 0 10px;
  background: #fff;
  font-size: 13px;
  color: var(--Gray);
  z-index: 1;
}

main.member section ul.sns {
}

main.member section ul.sns li + li {
  margin-top: 12px;
}

main.member section ul.sns button {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #fff;
  background-position: left 12px center;
  background-size: 30px;
  background-repeat: no-repeat;
  border: 1px solid var(--Light);
  color: var(--Gray);
  font-weight: 500;
  transition: all 300ms;
}

main.member section ul.sns button.naver {
  background-image: url("../assets/image/icon-naver.svg");
}

main.member section ul.sns button.kakao {
  background-image: url("../assets/image/icon-kakao.svg");
}

main.member section ul.sns button.google {
  background-image: url("../assets/image/icon-google.svg");
}

main.member section ul.sns button:hover {
  background-position: left 20px center;
  border-color: var(--Jet);
  color: var(--Jet);
}

main.member section dl.agree {
  margin-top: 30px;
}

main.member section dl.agree dt {
  font-weight: 500;
}

main.member section dl.agree dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

main.member section dl.agree dd p {
  font-size: 13px;
  font-weight: 500;
  color: var(--Strong);
  text-decoration: underline;
  cursor: pointer;
}

main.member section dl.agree dd label span {
  font-size: 14px;
  font-weight: 500;
}

main.member section ul.note {
  margin-top: 30px;
  padding: 16px;
  background: var(--Ghost);
  border-radius: 6px;
}

main.member section ul.note li {
  position: relative;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--Gray);
}

main.member section ul.note li::before {
  position: absolute;
  left: 0;
  top: 5px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: var(--Gray);
  content: "";
}

main.member p.cr {
  margin-top: 60px;
  color: var(--Gray);
  font-size: 13px;
  letter-spacing: 0.1em;
}
