ul.bbs-form {
  width: 100%;
}

ul.bbs-form li + li {
  margin-top: 20px;
}

ul.bbs-form input[type=text] {
  width: 100%;
  height: 40px;
}

section.content .quill {
  height: 40rem;
  margin-bottom: 7.5rem;
}

section.content {
  & textarea.comment {
    margin-top: 2rem;
    width: 100%;
  }
}

fieldset.post {
  display: flex;

  & input[type=text] {
    width: 24rem;
  }

  & div.nice-select {
    margin-right: 6px;

    & + input[type=text] {
      margin-left: 6px;
    }
  }

  & button {
    width: 4rem;
    height: 4rem;
    margin-left: 6px;
    background: var(--Jet) url("../assets/image/button-search-w.svg") center no-repeat;
    background-size: 24px;
    border: 0 none;
    text-indent: -9999px;

    &:hover {
      background-color: var(--Strong);
    }
  }
}
