
div.result-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-result-count {
    display: flex;

    & label.search {
      margin-left: 10px;
    }
  }

  & p.sum {
    font-size: 2.2rem;

    & strong {
      font-weight: 800;
      color: var(--Number);
    }
  }

  & div.func {
    display: flex;
    align-items: center;

    & label {
      padding: 9px;
      background: #fff;
      border: 1px solid var(--Bright);
      border-radius: 6px;
      box-sizing: border-box;
      transition: all 0.3s;
    }

    & label:hover {
      background: var(--Ghost);
    }

    & label:has(input[type=checkbox]:checked) {
      border-color: var(--Jet);
    }

    & button.keep {
      position: relative;
      height: 4rem;
      padding-left: 4rem;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;
        height: 4rem;
        background: url("../assets/image/icon-keep.svg") center no-repeat;
        background-size: 2rem;
        content: "";
      }

      &.export:after {
        background: url("../assets/image/icon-keep-export.svg") center no-repeat;
        background-size: 2rem;
      }

      &.delete:after {
        background: url("../assets/image/icon-keep-delete.svg") center no-repeat;
        background-size: 2rem;
      }

      &:hover {
        padding: 0 1.6rem 0 4.6rem;
      }
    }

    & label + button.keep, & button + button.keep {
      margin-left: 6px;
    }
  }

  & fieldset.sort {
    display: flex;

    & input[type=text] {
      width: 24rem;
    }

    & div.nice-select {
      margin-left: 6px;

      & + input[type=text] {
        margin-left: 6px;
      }
    }

    & button {
      width: 4rem;
      height: 4rem;
      margin-left: 6px;
      background: var(--Jet) url("../assets/image/button-search-w.svg") center no-repeat;
      background-size: 24px;
      border: 0 none;
      text-indent: -9999px;

      &:hover {
        background-color: var(--Strong);
      }
    }
  }
}