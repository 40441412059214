.tacto-material-search-modal {
  display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--KD-SNOW, #FFF);

  & .header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  & .title-container {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    & .badge {
      display: flex;
      height: 20px;
      padding: 0 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 6px;
      background: var(--KD-LOOK, #F90874);
      color: var(--KD-SNOW, #FFF);
      font-size: 12px;
      font-weight: 600;
      line-height: 100%;

      & .icon {
        width: 14px;
        height: 14px;
        background-image: url("../assets/image/badge-material-search.svg");
      }
    }

    & .title {
      color: var(--KD-JET, #333);
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
    }
  }

  & .description {
    color: var(--KD-GRAY, #999);
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
  }

  & .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    & .checkbox {
      display: flex;
      height: 40px;
      padding: 0 16px 0 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--KD-GRAY, #999);
      color: var(--KD-GRAY, #999);
      font-size: 14px;
      font-weight: 600;
      line-height: 100%;
    }

    & .search {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & input {
        width: 360px;
        height: 40px;
        padding: 0 12px;
      }

      & button {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        background-image: url("../assets/image/search.svg");
      }
    }
  }

  & .project-item-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-top: 2px solid var(--KD-JET, #333);
    min-height: 490px;
  }

  & .project-item-wrap {
    position: relative;
    display: flex;
    padding: 12px 12px 12px 40px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--KD-MIST, #DDD);

    & .sgl-check {
      position: absolute;
      left: 12px;
      top: 15px;
      width: 16px;
      height: 16px;
    }
  }

  & .material {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    & .title {
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 600;
      line-height: 130%;
    }

    & .content {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      color: var(--KD-GRAY, #999);
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;

      & div:last-child {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    & .divider {
      background: var(--KD-SILVER, #BBB);
      width: 1px;
      height: 10px;
    }
  }

  & .pagination-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .pagination {
      margin-top: 0;
    }
  }

  & .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}
