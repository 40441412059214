.tacto-menus {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  & .icon {
    width: 22px;
    height: 22px;

    &.project {
      background-image: url("../assets/image/project.svg");

      &.on {
        background-image: url("../assets/image/project-active.svg");
      }
    }

    &.tree {
      background-image: url("../assets/image/tree.svg");

      &.on {
        background-image: url("../assets/image/tree-active.svg");
      }
    }

    &.memo {
      background-image: url("../assets/image/memo.svg");

      &.on {
        background-image: url("../assets/image/memo-active.svg");
      }
    }

    &.article {
      background-image: url("../assets/image/article.svg");

      &.on {
        background-image: url("../assets/image/article-active.svg");
      }
    }

    &.material {
      background-image: url("../assets/image/material.svg");

      &.on {
        background-image: url("../assets/image/material-active.svg");
      }
    }

    &.trash {
      background-image: url("../assets/image/trash.svg");

      &.on {
        background-image: url("../assets/image/trash-active.svg");
      }
    }

    &.manual {
      background-image: url("../assets/image/help.svg");

      &.on {
        background-image: url("../assets/image/help-active.svg");
      }
    }
  }

  & .tacto-menu {
    width: 100%;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    background: var(--KD-GHOST, #F7F7F7);
    color: var(--KD-JET, #333);
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;

    &.on {
      background: var(--KD-ID, #502ACC);
      color: var(--KD-SNOW, #FFF);
    }
  }
}

.initial-workspace {
  display: flex;
  height: 480px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  & .info {
    color: var(--KD-GRAY, #999);
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
  }
}

.workspace-list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .recent-items-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    & .title {
      color: var(--KD-JET, #333);
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
    }

    & .recent-items {
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }

    & .recent-item {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      max-width: 189px;
      padding: 16px;
      gap: 16px;
      flex: 1 0 0;
      border-radius: 12px;
      border: 1px solid var(--KD-MIST, #DDD);

      & .project-name {
        color: var(--KD-SILVER, #BBB);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        font-size: 13px;
        font-weight: 700;
        line-height: 100%;
      }

      & .name {
        overflow: hidden;
        color: var(--KD-JET, #333);
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        height: 42px;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
      }

      & .updated-at {
        color: var(--KD-GRAY, #999);
        font-size: 13px;
        font-weight: 500;
        line-height: 100%;
      }

      & .badge {
        display: flex;
        height: 24px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;

        &.completed {
          background: var(--KD-LOOK, #F90874);
        }

        & .dot {
          width: 4px;
          height: 4px;
          background: #DDD;
          border-radius: 50%;
        }
      }
    }
  }

  & .search-menus {
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    & .menu {
      display: flex;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      color: var(--KD-GRAY, #999);
      border-bottom: 1px solid var(--KD-JET, #333);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;

      &.on {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 12px 12px 0px 0px;
        border-top: 1px solid var(--KD-JET, #333);
        border-right: 1px solid var(--KD-JET, #333);
        border-left: 1px solid var(--KD-JET, #333);
        border-bottom: none;
        color: var(--KD-JET, #333);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }
  }

  & .workspace-items {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  & .workspace-item-wrap {
    padding: 16px;
    display: flex;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--KD-MIST, #DDD);
  }

  & .workspace-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    & .project-name {
      color: var(--KD-SILVER, #BBB);
      font-size: 13px;
      font-weight: 700;
      line-height: 100%;
    }

    & .name {
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 600;
      line-height: 130%;
    }

    & .description {
      color: var(--KD-GRAY, #999);
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;
    }

    & .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    & .left-container {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 600px;

      & div:last-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & .divider {
        background: var(--KD-SILVER, #BBB);
        width: 1px;
        height: 10px;
      }
    }

    & .info-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .etc-info {
      display: flex;
      align-items: center;
      gap: 8px;

      & .topic-info {
        height: 24px;
        border-radius: 12px;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;
      }

      & .count-info {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--KD-GRAY, #999);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;

        & span {
          color: var(--KD-JET, #333);
          font-size: 14px;
          font-weight: 700;
          line-height: 100%;
        }

        & .divider {
          width: 1px;
          height: 10px;
          background: var(--KD-SILVER, #BBB);
        }
      }

    }

    & .progress-container {
      display: flex;
      align-items: center;
      gap: 8px;

      & .progress-badge {
        display: flex;
        height: 24px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-size: 12px;
        font-weight: 600;
        line-height: 100%;

        &.completed {
          background: var(--KD-LOOK, #F90874);
        }

        & .dot {
          width: 4px;
          height: 4px;
          background: #DDD;
          border-radius: 50%;
        }
      }

      & .updated-at {
        font-size: 12px;
        font-weight: 600;
      }
    }

    & .updated-at {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 500;
      line-height: 100%;
    }
  }

  & .trash-buttons {
    display: flex;
    flex-direction: column;;
    gap: 8px;

    & button {
      display: flex;
      width: 48px;
      height: 32px;
      padding: 0 12px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }

    & button.restore {
      background: var(--KD-POINT, #FFB800);
      color: var(--KD-JET, #333);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }

    & button.delete {
      position: relative;
      background: var(--KD-MIST, #DDD);
    }

    & button.delete::before {
      position: absolute;
      z-index: 1;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url("../assets/image/trash-delete.svg");
      background-size: 20px 20px;
      background-repeat: no-repeat;
    }
  }
}

button.project-creation {
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: 40px;
  padding: 0 12px;
  border-radius: 8px;
  background: var(--KD-ID, #502ACC);
  color: var(--KD-SNOW, #FFF);

  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
}

.workspace-item .attachment-memo-container,
.workspace-list .recent-items-wrap .recent-item .attachment-memo-container,
{
  display: flex;
  align-items: center;
  gap: 8px;

  & .memo-title {
    color: var(--KD-DIM, #777);
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
  }

  & .badge {
    display: flex;
    height: 24px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid var(--KD-SILVER, #BBB);
    background: var(--KD-SNOW, #FFF);
    color: var(--KD-SILVER, #BBB);
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;

    & .icon {
      width: 14px;
      height: 14px;

      &.memo {
        background-image: url("../assets/image/memo-button.svg");
      }

      &.attachment {
        background-image: url("../assets/image/attachment-button.svg");
      }
    }

    &.existed {
      background: var(--KD-JET, #333);
      color: var(--KD-SNOW, #FFF);

      & .icon {
        &.memo {
          background-image: url("../assets/image/memo-button-existed.svg");
        }

        &.attachment {
          background-image: url("../assets/image/attachment-button-existed.svg");
        }
      }
    }
  }
}