.spinner-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.fixed {
    position: fixed;
  }
}

.spinner {
  width: 200px;
  height: 200px;
  background: url("../assets/image/spinner.svg") no-repeat;
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}