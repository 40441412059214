.neo-container {
  width: var(--MinWidth);
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
}

.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

header {
  position: absolute;
  top: 0;
  width: 100%;
  min-width: var(--MinWidth);
  background: transparent;
  border-bottom: 1px solid var(--NeoMist);
  z-index: 9;
  transition: all 0.3s;

  & nav.gnb a {
    flex: 0 0 auto;
    padding: 8px;
    font-size: 15px;
    font-weight: 700;
    transition: all 0.3s;
  }

  & nav.util {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  & nav.util div button {
    flex: 0 0 auto;
    padding: 4px;
    font-size: 13px;
    font-weight: 500;
    color: var(--NeoGray);
    transition: all 0.3s;
  }

  & div.neo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }

  & h1 {
    flex: 0 0 192px;
    height: 32px;
    background: url("../assets/image/neo-h1-logo.svg");

    & a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  & nav {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  & nav a {
    flex: 0 0 auto;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
    transition: all 0.3s;
  }

  & nav.gnb a.on {
    font-weight: 800;
    color: var(--NeoPoint);
  }

  & nav.gnb a:not(.on):hover {
    padding-bottom: 11px;
    transform: translateY(-3px);
  }


  & fieldset.search {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 480px;
    margin: 0 auto;
    padding-right: 20px;
    border-bottom: 3px solid var(--Jet);
    box-sizing: border-box;
  }

  & fieldset.search div.nice-select {
    flex: 0 0 110px;
    height: 48px;
    padding-left: 20px;
    background: transparent;
    border: 0 none;
    font-weight: 500;
    line-height: 48px;
  }

  & fieldset.search input[type=text] {
    width: calc(100% - 200px);
    height: 48px;
    padding: 0;
    background: transparent;
    border: 0 none;
  }

  & fieldset.search button.simple-clear-button {
    width: 15px;
    height: 15px;
    padding: 0;
    background: transparent url("../assets/image/button-close.svg") no-repeat center;
    border: 0 none;
  }

  & fieldset.search button.simple-search-button {
    width: 24px;
    height: 24px;
    padding: 0;
    background: transparent url("../assets/image/neo-button-24-search.svg") no-repeat center;
    border: 0 none;
  }

  & fieldset.search button.detail-search-button {
    width: 72px;
    height: 24px;
    font-size: 14px;
    border-radius: 10px;
    background: black;
    color: #FFF;
  }

  & fieldset.search button.detail-search-button:hover {
    background: var(--Alert);
    border-color: var(--Alert);
  }

  & nav.util a:not(.on):hover {
    color: var(--NeoJet)
  }

  &.home:not(.scroll) nav.util a {
    color: rgba(255, 255, 255, 0.6);
  }

  &.home:not(.scroll) nav.util a:hover {
    color: var(--NeoSnow);
  }

  &.home:not(.scroll) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  &.home:not(.scroll) h1 {
    background-image: url("../assets/image/h1-logo-home.svg");
  }

  &.home:not(.scroll) nav.gnb a {
    color: var(--NeoSnow);
  }

  &.scroll {
    position: fixed;
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 12px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  @media (max-width: 768px) {
    position: fixed;
    min-width: unset;
    background: var(--NeoSnow);
    left: 0;
    right: 0;

    & div.neo-container {
      width: 100%;
      height: 102px;
      padding-bottom: 48px;
    }

    & nav.gnb {
      position: absolute;
      left: 0;
      right: 0;
      top: 54px;
      height: 48px;
      padding: 0 12px;
      background: var(--NeoID);
      overflow-x: scroll;
    }

    & nav.gnb a {
      color: var(--NeoSnow);
    }

    & fieldset.search {
      width: 100%;
    }

    &.scroll {
      background: var(--NeoSnow);
      border: 0 none;
    }
    &.home:not(.scroll) {
      border: 0 none;
    }
    &.home:not(.scroll) h1 {
      background-image: url("../assets/image/neo-h1-logo.svg");
    }
    &.home:not(.scroll) nav.util a {
      color: var(--NeoGray);
    }
    &.home:not(.scroll) nav.util a:hover {
      color: var(--NeoJet);
    }

    header + main {
      padding-top: 154px;
    }

    &.home + main {
      padding-top: 102px;
    }
  }

  @media (max-width: 480px) {
    & div.neo-container {
      padding-left: 0;
    }
    & h1,
    &.home:not(.scroll) h1 {
      flex: 0 0 54px;
      height: 54px;
      background-image: url("../assets/image/neo-h1-logo-K.svg");
    }
    & fieldset.search input[type=text]::placeholder {
      color: transparent !important;
    }
  }
}




