
.empty-items {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  height: 100px;
}

ul.table-type {
  position: relative;
}

ul.table-type:before {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  border-bottom: 3px solid var(--Jet);
  content: "";
}

ul.table-type > li {
}

ul.table-type div.info {
  display: flex;
}

ul.table-type dl {
  text-align: center;
  box-sizing: border-box;
}

ul.table-type dt,
ul.table-type dd {
  height: 50px;
  padding: 0 10px;
  line-height: 50px;
  border-bottom: 1px solid var(--Bright);
  max-width: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
}

ul.table-type dt {
  display: none;
}

ul.table-type > li:first-child dt {
  display: block;
  margin-bottom: 3px;
  border: 0 none;
  line-height: 50px;
  color: var(--Gray);
}

ul.bbs-list dl.number {
  flex: 0 0 80px;
}

ul.bbs-list dl.category {
  flex: 0 0 80px;

  &.wide {
    flex: 0 0 150px;
  }
}

ul.bbs-list dl.report-title {
  flex: 1 0 400px;

  &.narrow {
    flex: 1 0 auto;

    & dd {
      width: 334px;
    }
  }
}

ul.bbs-list dl.title {
  flex: 1 0 480px;

  &.narrow {
    flex: 1 0 auto;

    & dd {
      width: 414px;
    }
  }
}

ul.bbs-list dl.title dd {
  text-align: left;
  width: 480px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ul.bbs-list dl.writer {
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 140px;
}

ul.bbs-list dl.date {
  flex: 0 0 140px;
}

ul.bbs-list dl.view {
  flex: 0 0 80px;
}

ul.bbs-list a:hover {
  color: var(--Strong);
  text-decoration: underline;
  text-underline-position: under;
}
