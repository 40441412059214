h1.keep-head {
  display: flex;
  align-items: center;
}

h1.keep-head span.name {
  font-size: 30px;
  font-weight: 700;
}

h1.keep-head span.sum {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var(--Pointer);
}

h1.keep-head + * {
  margin-top: 20px;
}