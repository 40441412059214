.material-attachment-modal {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
  }

  & .material-title {
    color: var(--KD-JET, #333);
    width: 600px;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
  }

  & .item {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    & .label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
      line-height: 100%;
    }


    & .attachment-file-selector {
      position: relative;
      display: flex;
      height: 40px;
      padding: 0 4px;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--KD-MIST, #DDD);
      background: var(--KD-SNOW, #FFF);
      overflow: hidden;

      & input[type="file"] {
        border: none;
        max-width: 550px;

        &::file-selector-button {
          display: none;
        }
      }

      & .add-button {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 12px;
        top: 8px;
        background-image: url("../assets/image/attachment-add-button.svg");
      }
    }
  }

  & .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  & .pdf-viewer {
    height: 100%;
    width: 1440px;
    max-height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;

    & .delete-button {
      display: flex;
      height: 24px;
      padding: 0 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      background: var(--KD-JET, #555);
      color: var(--KD-SNOW, #FFF);
      border: 1px solid var(--KD-SNOW, #FFF);
      font-size: 12px;
      font-weight: 600;
      line-height: 100%;
      border-radius: 6px;

      & .delete-icon {
        width: 14px;
        height: 14px;
        background-image: url("../assets/image/delete-attachment.svg");
      }
    }

    & embed {
      width: 100%;
      height: 750px;
      border-radius: 24px;
    }
  }
}