.export-dropdown {
  -webkit-tap-highlight-color: transparent;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  color: white;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  height: 4.2rem;
  line-height: 38px;
  outline: none;
  margin-left: 5px;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 13rem;
  background: var(--Jet) ;

  .export-icon {
    background: url("../assets/image/icon-keep-export.svg") no-repeat center;
    background-size: 2rem;
  }

  & .current {
    height: 4rem;
    margin-left: 2rem;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 4rem;
      height: 4rem;
      background: url("../assets/image/icon-keep-export.svg") center no-repeat;
      background-size: 2rem;
      content: "";
    }
  }
}

.export-dropdown:hover {
  border-color: #dbdbdb;
}

.export-dropdown:active, .export-dropdown:focus {
  border-color: #999;
}

.export-dropdown.open {
  .option {
    color: black;
  }
}

.export-dropdown:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.export-dropdown.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.export-dropdown.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.export-dropdown.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.export-dropdown.disabled:after {
  border-color: #cccccc;
}

.export-dropdown.wide {
  width: 100%;
}

.export-dropdown.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.export-dropdown.right {
  float: right;
}

.export-dropdown.right .list {
  left: auto;
  right: 0;
}

.export-dropdown.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.export-dropdown.small:after {
  height: 4px;
  width: 4px;
}

.export-dropdown.small .option {
  line-height: 34px;
  min-height: 34px;
}

.export-dropdown .list {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.export-dropdown .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.export-dropdown .option {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.export-dropdown .option:hover, .export-dropdown .option.focus, .export-dropdown .option.selected.focus {
  background-color: #f6f6f6;
}

.export-dropdown .option.selected {
  font-weight: bold;
}

.export-dropdown .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .export-dropdown .list {
  display: none;
}

.no-csspointerevents .export-dropdown.open .list {
  display: block;
}
