input[type=checkbox] {
  -moz-appearance: initial // Hack for Firefox Browsers
}

input[type=checkbox].sgl-check {
  position: relative;
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 1;

  &::before {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    background: #fff;
    border: 1px solid var(--Light);
    border-radius: 4px;
    box-sizing: border-box;
    content: "";
    z-index: 1;
  }

  &:checked::before {
    border-color: var(--Jet);
  }

  &:checked::after {
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    left: 4px;
    top: 4px;
    background: var(--Jet);
    border-radius: 2px;
    content: "";
    z-index: 2;
  }
}
