figure.journal-cover {
  width: 12rem;
  height: 16.5rem;
  border-radius: 6px;
  overflow: hidden;
}

figure.journal-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul.journal-info {
  margin-top: 1.6rem;
}

ul.journal-info li + li {
  margin-top: 8px;
}

ul.journal-info dl {
  display: flex;
}

ul.journal-info dl dt {
  flex: 0 0 8rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--Gray);
}

ul.journal-info dl dd {
  flex: 0 0 auto;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.3;
  width: 90%;
  text-overflow: ellipsis;
}

ul.journal-info + * {
  margin-top: 2rem;
}
