div.search-condition {
  display: flex;


  & dl {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 2px;
    background: var(--Strong);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
  }

  & dt {
    padding: 6px 8px;
    color: #fff;
  }

  & dd {
    padding: 6px 8px;
    background: #fff;
    border-radius: 4px;
    font-weight: 600;
    color: var(--Strong);
  }
}

div.filter-btn {
  position: fixed;
  bottom: 30px;
  width: 238px;
  margin-top: 20px;


  button.execute {
    display: block;
    width: 100%;
    height: 60px;
    padding-right: 46px;
    background: var(--Strong);
    border-color: var(--Strong);
    border-radius: 10px;
    color: white;
  }

  button.execute:hover {
    background-color: var(--Stronger);
    border-color: var(--Stronger);
  }

  button.reset {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 32px;
    height: 32px;
    background: transparent url("../assets/image/button-reset.svg") no-repeat center / 18px;
    border: 2px solid #fff;
    border-radius: 16px;
    text-indent: -9999px;
    opacity: 0.6;
  }

  button.reset:hover {
    opacity: 1.0;
  }
}