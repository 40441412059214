.articles-storage {

  &.popup {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: fit-content;
  }

  & ul.my-keep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: fit-content;
    gap: 20px;
    padding-left: 8px;
    padding-right: 8px;

    & div.storages {
      width: 100%;
      height: 11rem;
      display: flex;
      flex-direction: column;
      gap: 6px;
      overflow: scroll;
    }

    & div.storages.modal-mode {
      width: 40rem;
      height: 15rem;
    }

    & li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      & span {
        padding-left: 20px;
        background: url("../assets/image/icon-folder.svg") left center no-repeat;
        background-size: 16px;
        font-size: 15px;
        font-weight: 500;
        transition: all 0.3s;

        &.modal-mode {
          padding-left: 30px;
          background-size: 20px;
          font-size: 20px;
        }

        &.on {
          background-image: url("../assets/image/icon-folder-on.svg");
          font-weight: 600;
          color: var(--Strong);
        }

        &:not(.on):hover {
          color: var(--Strong);
          text-decoration: underline;
          text-underline-position: under;
        }
      }

      & div.func {
        display: flex;
        gap: 6px;
        font-size: 0;

        & button {
          width: 2.8rem;
          height: 2.8rem;
          margin: auto;
          background-color: #fff;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 1.8rem;
          border: 1px solid var(--Bright);
          border-radius: 50%;
          text-indent: -9999px;

          &.edit {
            background-image: url("../assets/image/icon-folder-edit.svg");

            &:hover {
              background-image: url("../assets/image/icon-folder-edit-w.svg");
            }
          }

          &.delete {
            background-image: url("../assets/image/icon-folder-delete.svg");

            &:hover {
              background-image: url("../assets/image/icon-folder-delete-w.svg");
            }
          }

          &:hover {
            background-color: var(--Gray);
            border-color: var(--Gray);
          }
        }
      }
    }

    & li.add {
      display: block;
      position: relative;

      & input[type=text] {
        width: 100%;
        height: 3rem;
        font-size: 1.4rem;
      }

      & button {
        position: absolute;
        top: 0;
        right: 0;
        height: 3rem;
        border-radius: 0 6px 6px 0;
        font-size: 1.4rem;
      }
    }
  }
}
