
div.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 4rem;

  & span {
    min-width: 36px;
    height: 36px;
    padding: 0 8px;
    text-align: center;
    border: 1px solid var(--Bright);
    border-radius: 4px;
    font-size: 15px;
    line-height: 34px;
    color: var(--Gray);
    box-sizing: border-box;
    transition: all 0.3s;

    &.move {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      text-indent: -9999px;
    }

    &.first {
      background-image: url("../assets/image/pagination-first.svg");

      &:hover {
        background-image: url("../assets/image/pagination-first-hover.svg");
      }
    }

    &.prev {
      background-image: url("../assets/image/pagination-prev.svg");

      &:hover {
        background-image: url("../assets/image/pagination-prev-hover.svg");
      }
    }

    &.next {
      background-image: url("../assets/image/pagination-next.svg");

      &:hover {
        background-image: url("../assets/image/pagination-next-hover.svg");
      }
    }

    &.last {
      background-image: url("../assets/image/pagination-last.svg");

      &:hover {
        background-image: url("../assets/image/pagination-last-hover.svg");
      }
    }

    &.active {
      background: var(--Number);
      border-color: var(--Number);
      color: #fff;
      font-weight: 600;
    }

    &:not(.active):hover {
      background-color: var(--Ghost);
      border-color: var(--Jet);
      color: var(--Jet);
    }
  }
}