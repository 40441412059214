hgroup.journal-head {

  & h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
  }

  & h2 {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.3;
    color: var(--Cloud);
  }

  & + * {
    margin-top: 2rem;
  }
}
