footer {
  padding: 4rem;
  background: var(--Jet);
  text-align: center;
  font-size: 1.3rem;
  color: var(--Gray);
  box-sizing: border-box;

  & h1 {
    font-size: 1.5rem;
  }

  & p {
    margin-top: 1rem;

    & span {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  & ul.menu {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1.6rem;

    & li {
      flex: 0 0 auto;
    }

    & a {
      display: inline-block;
      font-weight: 500;
      color: #fff;
      transition: all 0.3s;

      &:hover {
        color: var(--Point);
      }
    }
  }

  & p.cr {
    margin-top: 2rem;
    letter-spacing: 0.1em;
  }
}