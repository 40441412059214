.create-topic-by-document {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
  }

  & .creation-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .document-options {
      display: flex;
      align-items: center;
      align-content: center;
      gap: 12px;
      align-self: stretch;
      flex-wrap: wrap;

      & .document-option {
        display: flex;
        height: 45px;
        min-width: 150px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 12px;
        border: 1px solid var(--KD-MIST, #DDD);

        &.on {
          border: 1px solid var(--KD-JET, #333);
          background: var(--KD-SNOW, #FFF);
        }
      }
    }

    & .option-description {
      color: var(--KD-GRAY, #999);
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
    }
  }

  & .selection-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .selection-label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
      line-height: 100%;
    }

    & .selection-input {
      position: relative;
      display: flex;
      height: 40px;
      padding: 0 16px;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--KD-MIST, #DDD);
      background: var(--KD-SNOW, #FFF);

      &.text {
        height: 200px;

        & textarea {
          height: 100%;
        }
      }

      & textarea, input[type="text"]  {
        width: 100%;
        border: none;

        &::file-selector-button {
          display: none;
        }

        &:read-only {
          background-color: white;
        }
      }
    }
  }

  & .buttons {
    position: absolute;
    right: 0;
    bottom: 0;

    & button {
      display: flex;
      height: 40px;
      padding: 0 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
    }

    & .cancel {
      border: 1px solid var(--KD-JET, #333);
      color: var(--KD-JET, #333);
    }

    & .confirm {
      background: var(--KD-JET, #333);
      color: var(--KD-SNOW, #FFF);
    }
  }

  & .suggestion-container {
    height: 80%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .topic-tree {
      position: relative;
      height: 100%;
      max-height: 476px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;

      & ul.children {
        margin-left: 34px;
      }

      & div.children {
        margin-top: 8px;
      }

      & .tree-node-item {
        min-width: 325px;
        max-width: 450px;
        height: 36px;
        min-height: 36px;
        padding: 4px 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);
      }
    }
  }
}