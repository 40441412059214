.storage-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 30rem;
  padding: 0 3rem 0 3rem;

  & .modify-input {
    width: 100%;
    height: 4rem;
  }

  & .modify-button {
    height: 4rem;
    font-weight: 700;
  }
}