:root {
  --Black: #111;
  --Jet: #333;
  --Dark: #555;
  --Dim: #777;
  --Gray: #999;
  --Cloud: #BBB;
  --Light: #DDD;
  --Bright: #E1E1E1;
  --Ghost: #F5F5F5;
  --Strong: #502ACC;
  --Stronger: #4B0A9E;
  --Point: #F4A816;
  --Pointer: #DC284E;
  --Number: #F90874;
  --Alert: #E21000;
  --Link: #2380FF;
  --NeoNight: #111;
  --NeoJet: #333;
  --NeoDark: #555;
  --NeoDim: #777;
  --NeoGray: #999;
  --NeoCloud: #BBB;
  --NeoMist: #DDD;
  --NeoGhost: #F5F5F5;
  --NeoSnow: #FFF;

  --NeoID: #502ACC;
  --NeoPoint: #FF7782;
  --NeoHighlight: #FFBB55;

  --MinWidth: 1280px;
}

html {
  height: 100%;
  font-size: 16px * 0.625;
}

body {
  position: relative;
  height: 100%;
  font: 400 16px/1.0 "Pretendard", "Noto Sans SC", sans-serif;
  letter-spacing: 0;
  color: var(--Jet);
  background: #fff;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.container {
  max-width: 120rem;
  padding: 0 4rem;
  margin: 0 auto;
}

.number {
  font-family: "Rubik", sans-serif;
}

div.above + * {
  margin-top: 20px;
}

input[type=text],
input[type=search],
input[type=password],
input[type=file],
input[type=number],
input[type=date],
select,
textarea {
  display: inline-block;
  padding: 0 1rem;
  background: #fff;
  border: 1px solid var(--Light);
  border-radius: 6px;
  font-size: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  transition: all 0.3s;
}

textarea {
  padding: 1rem;
  line-height: 1.3;
  overflow: overlay;
  resize: none;
}

textarea::-webkit-scrollbar {
  width: 0.8rem;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

input[type=number] {
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  padding-right: 2.5rem;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=number]:focus,
select:focus,
textarea:focus {
  border-color: var(--Jet);
  color: var(--Jet);
}

input::placeholder,
textarea::placeholder {
  color: var(--Cloud);
  font-weight: 400 !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--Cloud);
  font-weight: 400 !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--Cloud);
  font-weight: 400 !important;
}

input[type=text]:read-only {
  background: var(--Ghost);
  border-color: var(--Light);
  color: var(--Dim) !important;
}

label.checkbox,
label.radio {
  position: relative;
  display: inline-block;
  font-size: 0;
  line-height: 20px;
  vertical-align: middle;
}

label.checkbox + label,
label.radio + label {
  margin-left: 20px;
}

label.checkbox input[type=checkbox],
label.radio input[type=radio] {
  position: absolute;
  width: 0;
  height: 0;
  display: none;
}

label.checkbox span,
label.radio span {
  position: relative;
  display: inline-block;
  padding-left: 26px;
  font-size: 16px;
  color: var(--Gray);
  vertical-align: middle;
  transition: all 0.3s;
}

label.checkbox input[type=checkbox]:checked + span,
label.radio input[type=radio]:checked + span {
  color: var(--Jet);
}

label.checkbox span::before {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  margin-top: -10px;
  background: #fff;
  border: 1px solid var(--Light);
  border-radius: 4px;
  box-sizing: border-box;
  content: "";
}

label.checkbox input[type=checkbox]:checked + span::before {
  border-color: var(--Jet);
}

label.checkbox input[type=checkbox]:checked + span::after {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  left: 4px;
  top: 50%;
  margin-top: -6px;
  background: var(--Jet);
  border-radius: 2px;
  content: "";
}

ul.table-type {
  position: relative;
}

ul.table-type:before {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  border-bottom: 3px solid var(--Jet);
  content: "";
}

ul.table-type > li {
}

ul.table-type div.info {
  display: flex;
}

ul.table-type dl {
  text-align: center;
  box-sizing: border-box;
}

ul.table-type dt,
ul.table-type dd {
  height: 50px;
  padding: 0 10px;
  line-height: 50px;
  border-bottom: 1px solid var(--Bright);
  max-width: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
}

ul.table-type dt {
  display: none;
}

ul.table-type > li:first-child dt {
  display: block;
  margin-bottom: 3px;
  border: 0 none;
  line-height: 50px;
  color: var(--Gray);
}

div.button-wrap {
  display: flex;
  margin-top: 20px;
}

div.button-wrap.destro {
  justify-content: flex-end;
  gap: 1rem;
}

div.button-wrap button {
  height: 40px;
}

div.button-wrap a.button {
  height: 40px;
  line-height: 38px;
}

div.button-wrap button:hover,
div.button-wrap a.button:hover {
  padding: 0 16px;
}

h1.normal-head {
  font-size: 30px;
  font-weight: 700;
}

h2.normal-head {
  font-size: 24px;
  font-weight: 700;
}

h3.normal-head {
  font-size: 20px;
  font-weight: 700;
  text-decoration: underline;
  text-underline-position: under;
}

h1.centro,
h2.centro,
h3.centro {
  text-align: center;
}

h1.normal-head + *,
h2.normal-head + *,
h3.normal-head + * {
  margin-top: 20px;
}

* + h1.normal-head,
* + h3.normal-head {
  margin-top: 20px;
}

* + h2.normal-head {
  margin-top: 30px;
}

label.radio span::before {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  margin-top: -10px;
  background: #fff;
  border: 1px solid var(--Light);
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
}

label.radio input[type=radio]:checked + span::before {
  border-color: var(--Jet);
}

label.radio input[type=radio]:checked + span::after {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  left: 4px;
  top: 50%;
  margin-top: -6px;
  background: var(--Jet);
  border-radius: 50%;
  content: "";
}

dialog {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  position: fixed;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}