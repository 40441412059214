
ul.author-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > li {
    border: 1px solid var(--Bright);
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.3s;

    & > div {
      display: block;
      padding: 2rem;
    }
  }

  & h1 span {
    &.ko {
      font-size: 2.2rem;
      font-weight: 700;
      transition: all 0.3s;
    }

    &.en {
      font-size: 1.6rem;
      font-weight: 500;
      color: var(--Gray);
      transition: all 0.3s;
    }
  }

  & p.title {
    margin-top: 1.2rem;

    & span {
      display: block;
      line-height: 1.3;

      &.main {
        font-size: 1.8rem;
        font-weight: 600;
      }

      &.sub {
        margin-top: 6px;
        font-size: 1.6rem;
        font-weight: 500;
        color: var(--Gray);
      }
    }
  }

  & p.journal {
    display: inline-block;
    margin-top: 12px;
    padding: 6px;
    background: var(--Gray);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: all 0.3s;
  }
}

ul.author-list > li:hover {
  border-color: var(--Strong);
}

ul.author-list > li:hover h1 span.ko {
  color: var(--Strong);
}

ul.author-list > li:hover p.journal {
  background: var(--Dim);
}
