.add-external-content {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
  }

  & .add-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .document-options {
      display: flex;
      align-items: center;
      align-content: center;
      gap: 12px;
      align-self: stretch;
      flex-wrap: wrap;

      & .document-option {
        display: flex;
        height: 64px;
        width: 100%;
        min-width: 150px;
        max-width: 170px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 12px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-GHOST, #F7F7F7);
        color: var(--KD-SILVER, #BBB);
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 100%;

        &.on {
          color: var(--KD-JET, #333);
          border: 1px solid var(--KD-JET, #333);
          background: var(--KD-SNOW, #FFF);
        }
      }
    }

    & .option-description {
      color: var(--KD-GRAY, #999);
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
    }

    & .source-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      & .source-label {
        color: var(--KD-GRAY, #999);
        font-size: 13px;
        font-weight: 600;
        line-height: 100%;
      }

      & .source-input {
        position: relative;
        display: flex;
        height: 40px;
        padding: 0 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);

        & input[type="file"], input[type="text"] {
          width: 100%;
          border: none;

          &::file-selector-button {
            display: none;
          }
        }

        & .add-button {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 12px;
          top: 8px;
          background-image: url("../assets/image/attachment-add-button.svg");
        }
      }
    }
  }

  & .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    & .items {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }

    & .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      & .label {
        color: var(--KD-GRAY, #999);
        font-size: 13px;
        font-weight: 600;
        line-height: 100%;
      }

      & input[type='text'] {
        width: 100%;
        display: flex;
        height: 40px;
        padding: 0 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: var(--KD-JET, #333);
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }

      & textarea {
        display: flex;
        height: 110px;
        padding: 12px 16px;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);
        color: var(--KD-JET, #333);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
    }

    & .memo-section {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--KD-GHOST, #F7F7F7);
    }
  }

  & .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    & button {
      display: flex;
      width: 120px;
      height: 48px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
    }

    & .cancel {
      border: 1px solid var(--KD-ID, #502ACC);
      color: var(--KD-ID, #502ACC);
    }

    & .confirm {
      background: var(--KD-ID, #502ACC);
      color: var(--KD-SNOW, #FFF);
    }
  }
}