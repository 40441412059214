.delete-restore-item-modal {
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  background: var(--KD-SNOW, #FFF);

  & .title {
    color: var(--KD-JET, #333);
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
  }

  & .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--KD-GRAY, #999);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;

    & .alert-icon {
      width: 48px;
      height: 48px;
      background-image: url("../assets/image/delete-alert.svg");
    }
  }

  & .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }
}