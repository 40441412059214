.delete-topic-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
  }

  & .delete-form-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .delete-form {
    padding: 12px;
    width: 100%;
    height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--KD-SNOW, #FFF);
    gap: 12px;

    & .delete-alert {
      width: 48px;
      height: 48px;
      background-image: url("../assets/image/delete-alert.svg");
    }

    & .message {
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;

      & span {
        color: var(--KD-ID, #502ACC);
        font-weight: 800;
      }
    }
  }
}