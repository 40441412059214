
article.thesis-detail {

  & hgroup {
    & h1 {
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.3;
    }

    & h2 {
      margin-top: 1rem;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3;
      color: var(--Cloud);
    }
  }

  & div.info {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & dl {
      display: flex;
      align-items: flex-start;

      & dt {
        flex: 0 0 6rem;
        height: 2.4rem;
        padding: 0 8px;
        background: var(--Dim);
        border-radius: 6px;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: #fff;
        box-sizing: border-box;
      }

      & dd {
        flex: 0 1 auto;
        margin-left: 1.6rem;

        & p {
          padding-top: 3px;
          line-height: 1.3;
        }

        & p.abstracts-title {
          font-weight: bold;
        }

        & p + p {
          margin-top: 6px;
        }

        & span.sub {
          font-size: 1.4rem;
          color: var(--Gray);
        }

        & a {
          color: var(--Strong);
          text-decoration: underline;
          text-underline-position: under;
        }
      }
    }
  }

  & div.func {
    display: flex;
    margin-top: 2rem;

    & a {
      position: relative;
      flex: 0 0 auto;
      height: 3rem;
      padding: 0 1rem 0 3rem;
      background-color: #fff;
      border: 1px solid var(--Bright);
      border-radius: 6px;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 3rem;
      transition: all 0.3s;

      &:hover {
        padding: 0 1.6rem 0 3.6rem;
        border-color: var(--Strong);
        color: var(--Strong);
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 3rem;
        height: 3rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1.6rem;
        content: "";
      }
    }

    & a + a {
      margin-left: 8px;
    }
  }

  & ul.keyword {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;

    & li {
      flex: 0 0 auto;
      height: 2.6rem;
      margin: 2px;
      padding: 0 8px;
      border: 1px solid var(--Bright);
      border-radius: 6px;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 24px;
      box-sizing: border-box;
    }

    & li:hover {
      cursor: pointer;
    }
  }
}

article.thesis-detail div.func a.origin:after {
  background-image: url("../assets/image/icon-link-origin.svg");
}

article.thesis-detail div.func a.download:after {
  background-image: url("../assets/image/icon-link-download.svg");
}

article.thesis-detail div.func a.website:after {
  background-image: url("../assets/image/icon-link-website.svg");
}

article.thesis-detail div.func a.detail:after {
  background-image: url("../assets/image/icon-link-detail.svg");
}

article.thesis-detail div.func a.bookmark:after {
  background-image: url("../assets/image/icon-link-bookmark.svg");
}

div.others {

  & > h1 {
    padding-left: 3rem;
    background: url("../assets/image/icon-thesis.svg") center left no-repeat;
    background-size: 2.4rem;
    font-size: 2rem;
    font-weight: 700;
  }

  & ul {
    margin-top: 2rem;

    & li {
      border: 1px solid var(--Bright);
      border-radius: 1rem;
      overflow: hidden;
      transition: all 0.3s;

      & span.author {
        display: flex;
        padding: 16px;
        box-sizing: border-box;
        justify-content: space-between;

        & div {
          & p.h1 {
            font-weight: bold;
          }

          & p.h2 {
            font-size: 1.3rem;
          }
        }
      }

      & span.other-article {
        display: block;
        padding: 16px;
        box-sizing: border-box;

        &:hover {
          background: var(--Strong);
          border-color: var(--Strong);
          color: #fff;
        }
      }
    }

    & li + li {
      margin-top: 6px;
    }
  }

  & ul p {
    & span {
      transition: all 0.3s;
    }

    &.title span {
      display: block;
    }

    &.title span.h1 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
    }

    &.title span.h2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      color: var(--Gray);
    }

    &.publish {
      margin-top: 12px;
      font-size: 13px;
      font-weight: 600;

      & span.journal {
        transition: all 0.3s;
      }

      & span.year {
        color: var(--Gray);
      }
    }
  }
}