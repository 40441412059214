
div.pop-wrap {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  transition: all 0.3s;
  z-index: 10;
}

div.pop-wrap.on {
  background: rgba(0, 0, 0, 0.3);
  visibility: visible;
}

div.pop-window {
  position: relative;
  width: 480px;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  transform: translate(0, 100px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 12;
}

div.pop-wrap.on div.pop-window {
  transform: translate(0, 0);
  opacity: 1.0;
  visibility: visible;
}

div.pop-window > h1 {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--Bright);
}

div.pop-window > h1 span {
  font-size: 20px;
  font-weight: 700;
}

div.pop-window div.pop-cnt {
  max-height: calc(100vh - 400px);
  margin-top: 20px;
  padding: 0 20px 20px;
  overflow-y: auto;
}

div.pop-window div.pop-cnt p.guide {
  text-align: center;
  font-size: 15px;
  line-height: 1.3;
  color: var(--Gray);
}

div.pop-wrap button.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  background: transparent url("../assets/image/button-close.svg") center no-repeat;
  background-size: auto 12px;
  border: 0 none;
  border-radius: 50%;
  text-indent: -9999px;
}

div.pop-wrap button.close:hover {
  background-color: var(--Jet);
  background-image: url("../assets/image/button-close-hover.svg");
}

div.doc h1 {
  font-size: 18px;
  font-weight: 700;
}

div.doc * + h1 {
  margin-top: 20px;
}

div.doc h2 {
  font-size: 15px;
  font-weight: 600;
}

div.doc * + h2 {
  margin-top: 16px;
}

div.doc p {
  font-size: 13px;
  line-height: 1.3;
}

div.doc p.head {
  font-weight: 600;
}

div.doc > * + p {
  margin-top: 10px;
}

div.doc > ol {
  margin-top: 10px;
}

div.doc > ol > li + li {
  margin-top: 6px;
}

div.doc > ol > li > ol {
  padding-left: 10px;
}

div.doc > ol > li > * + p,
div.doc > ol > li > * + ol {
  margin-top: 6px;
}

div.doc > ol > li > ol > li + li {
  margin-top: 4px;
}

div.doc > ol > li > ol > li p.sub {
  font-size: 12px;
  line-height: 1.3;
  color: var(--Gray);
}

div.doc > ol > li > ol > li * + p {
  margin-top: 4px;
}

div.doc div.supple {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--Bright);
}

div.doc div.supple > * + p {
  margin-top: 12px;
}
