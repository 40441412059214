.simple-manual {
  display: flex;
  width: 920px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  & .title-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .title {
      color: var(--KD-JET, #333);
      font-size: 30px;
      font-weight: 700;
      line-height: 100%;

      & > span {
        margin-left: 8px;
        color: var(--KD-SILVER, #BBB);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  & .sections {
    display: flex;
    flex-direction: column;
    gap: 45px;
  }

  & .section {
    display: flex;
    flex-direction: column;
    gap: 25px;

    & .title {
      color: var(--KD-JET, #333);
      font-size: 24px;
      font-weight: 700;
      line-height: 125%;
    }

    & .help {
      color: var(--KD-JET, #333);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }

    & .images {
      display: flex;
      justify-content: center;
      gap: 12px;

      & > img {
        width: 100%;
        border-radius: 5px;
        border: 1px solid var(--KD-SILVER, #BBB);
      }
    }
  }
}