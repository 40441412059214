.creation-topic-based-existed-topic {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .title {
    color: var(--KD-JET, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
  }

  & .nice-select {
    width: 100%;

    & .list {
      width: 100%;
    }
  }

  & .buttons {
    position: absolute;
    right: 0;
    bottom: 0;

    & button {
      display: flex;
      height: 40px;
      padding: 0 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
    }

    & .cancel {
      border: 1px solid var(--KD-JET, #333);
      color: var(--KD-JET, #333);
    }

    & .confirm {
      background: var(--KD-JET, #333);
      color: var(--KD-SNOW, #FFF);
    }
  }

    & .topic-tree {
      position: relative;
      height: 100%;
      max-height: 360px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      overflow: scroll;

      & ul.children {
        margin-left: 34px;
      }

      & div.children {
        margin-top: 8px;
      }

      & .tree-node-item {
        min-width: 325px;
        max-width: 450px;
        height: 36px;
        min-height: 36px;
        padding: 4px 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);

        &.on {
          border: 1px solid var(--KD-JET, #333);
          background: var(--KD-SNOW, #FFF);
        }
      }
    }
}