header {
  .popup {
    display: flex;
    padding: 20px;
    background: #fff;

    & dl + dl {
      margin-left: 16px;
    }

    & dl dt {
      font-size: 14px;
      font-weight: 500;
      color: var(--Gray);
    }

    & dl dd {
      margin-top: 8px;
      font-size: 18px;
      font-weight: 600;
      color: var(--Strong);
    }
  }
}

div.table-wrapper {
  border: 1px solid var(--Bright);
  border-radius: 10px;
  overflow: hidden;
}

div.table-wrapper table {
  width: 100%;
}

div.table-wrapper table.f14 {
  font-size: 14px;
}

div.table-wrapper table thead th {
  padding: 10px 6px;
  background: var(--Ghost);
  border-bottom: 1px solid var(--Bright);
  font-weight: 600;
}

div.table-wrapper table tbody tr td {
  padding: 10px 6px;
  transition: all 0.3s;
}

div.table-wrapper table tbody tr td.number {
  text-align: center;
}

div.table-wrapper table tbody tr td:hover {
  background: var(--Ghost);
}

div.table-wrapper table tbody tr:not(:last-child) td {
  border-bottom: 1px solid var(--Bright);
}

div.table-wrapper table thead th + th,
div.table-wrapper table tbody td + td {
  border-left: 1px solid var(--Bright);
}

div.table-wrapper table a.link {
  color: var(--Link);
  text-decoration: underline;
}

div.table-wrapper table button.new-open {
  padding: 0;
  background: none;
  border: 0 none;
  font-weight: 400;
  color: var(--Link);
}

div.table-wrapper table button.new-open:hover {
  color: var(--Strong);
}