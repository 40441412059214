:root {
  --Black: #111;
  --Jet: #333;
  --Dark: #555;
  --Dim: #777;
  --Gray: #999;
  --Cloud: #BBB;
  --Light: #DDD;
  --Bright: #E1E1E1;
  --Ghost: #F5F5F5;
  --Blue: #47b3ed;
  --Strong: #502ACC;
  --Stronger: #5B40B6;
  --Point: #F4A816;
  --Pointer: #DC284E;
  --Number: #F90874;
}