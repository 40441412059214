.create-topic-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  width: 100%;
  height: 100%;

  & .create-form-wrap {
    width: 100%;
  }

  & .title {
    color: var(--KD-JET, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
  }

  & .create-form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .label {
      color: var(--KD-GRAY, #999);
      font-size: 13px;
      font-weight: 600;
    }

    & .text-box-wrap {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      & input {
        display: flex;
        height: 40px;
        padding: 0 16px;
        align-items: center;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid var(--KD-MIST, #DDD);
        background: var(--KD-SNOW, #FFF);
      }

      & button {
        display: flex;
        height: 40px;
        padding: 0 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
      }
    }
  }

  & .or {
    width: 100%;
    color: var(--KD-GRAY, #999);
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 100%;
  }

  & .creation-options {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 6px;

    & .creation-option {
      display: flex;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      border-radius: 12px;
      background: var(--KD-SNOW, #FFF);

      & .option-name {
        color: var(--KD-JET, #333);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }

      & .option-description {
        color: var(--KD-GRAY, #999);
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 130%;
      }

      & button {
        display: flex;
        height: 40px;
        padding: 0 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: var(--KD-JET, #333);
        color: var(--KD-SNOW, #FFF);
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
      }

      & .icon {
        width: 48px;
        height: 48px;

        &.tree {
          background-image: url("../assets/image/topic-creation-tree.svg");
        }

        &.document {
          background-image: url("../assets/image/topic-creation-document.svg");
        }

        &.database {
          background-image: url("../assets/image/topic-creation-database.svg");
        }
      }
    }
  }
}