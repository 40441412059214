div.filter-letter {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  margin: -4px;

  & button {
    flex: 0 0 5rem;
    height: 3rem;
    margin: 4px;
    padding: 6px;
    background: #fff;
    border-color: var(--Cloud);
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--Gray);

    &.on {
      background: var(--Strong);
      border-color: var(--Strong);
      color: #fff;
      font-weight: 600;
    }

    &:not(.on):hover {
      background-color: var(--Ghost);
      border-color: var(--Jet);
      color: var(--Jet);
    }
  }
}